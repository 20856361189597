import * as React from 'react';
import { RouteObject } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DialogProvider } from './contexts/Dialog';
import { BMAFormProvider } from './views/BusinessMessageAgent/BMAForm';
import BulkVerificationToolForm from './views/BusinessMessageAgent/BusinessMessageAgentVerifyForm';
import ChatWidgetConfigurationForm from './views/ChatWidgetConfigurationForm';
import Logout from './views/Logout';
import ReviewWidgetConfigurationForm from './views/ReviewWidgetConfigurationForm';

export interface NavRoute extends RouteObject {
  nav: string;
  icon?: IconProp;
}

export type nestedNavRoute = [string, ...NavRoute[]];

export interface NavRoutes {
  top: (NavRoute | nestedNavRoute)[];
  bottom: NavRoute[];
}

const NavRoutes: NavRoutes = {
  top: [
    {
      path: '/verify-business-message-agent',
      nav: 'Business Message Agent Verification',
      element: (
        <DialogProvider>
          <BMAFormProvider>
            <BulkVerificationToolForm />
          </BMAFormProvider>
        </DialogProvider>
      ),
    },
    [
      'Widgets',
      {
        path: '/chat',
        nav: 'Chat Widgets',
        icon: ['fas', 'comment'],
        element: (
          <DialogProvider>
            <ChatWidgetConfigurationForm />
          </DialogProvider>
        ),
      },
      {
        path: '/reviews',
        nav: 'Review Widgets',
        icon: ['fas', 'quote-left'],
        element: (
          <DialogProvider>
            <ReviewWidgetConfigurationForm />
          </DialogProvider>
        ),
      },
    ],
  ],
  bottom: [
    {
      path: '/logout',
      nav: 'Logout',
      icon: ['fas', 'sign-out'],
      element: <Logout />,
    },
  ],
};

export default NavRoutes;
