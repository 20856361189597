import * as React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { darkTheme, lightTheme } from '../theme';

type ColorModes = 'light' | 'dark';

interface ThemeContext {
  isDark: boolean;
  isLight: boolean;
  updateColorMode(color: ColorModes): void;
}

const ThemeContext = React.createContext<ThemeContext>({
  isDark: false,
  isLight: false,
  updateColorMode: () => null,
});

export default ThemeContext;

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [colorMode, setColorMode] = React.useState<ColorModes>('dark');

  const updateColorMode = (mode: ColorModes) => {
    localStorage.setItem('mode', mode);
    setColorMode(mode);
  };

  React.useEffect(() => {
    if (localStorage.getItem('mode')) {
      updateColorMode(localStorage.getItem('mode') as ColorModes);
    }
  }, []);

  const isDark = colorMode === 'dark';
  const isLight = colorMode === 'light';

  const value = React.useMemo(
    () => ({
      isDark,
      isLight,
      updateColorMode,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colorMode]
  );

  const theme = React.useMemo(() => (colorMode === 'dark' ? darkTheme : lightTheme), [colorMode]);

  return (
    <ThemeContext.Provider value={value}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}
