import React from 'react';
import { Navigate } from 'react-router-dom';
import { authConsumer } from '../utils/auth-consumer';

const Logout = (): JSX.Element => {
  const logout = async () => {
    await authConsumer.endSession();
    window.location.assign(window.location.origin);
  };

  React.useEffect(() => {
    logout();
  }, []);

  return <Navigate replace to="/" />;
};

export default Logout;
