import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { motion } from 'framer-motion';
import _, { PropertyPath } from 'lodash';

export interface FormFieldProps<Values extends FormikValues> extends StandardTextFieldProps {
  values: Values;
  touched: FormikTouched<Values>;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
  };
  handleChange: { (e: React.ChangeEvent<any>): void };
  errors: FormikErrors<Values>;
  fieldKey: Extract<keyof Values, string> | PropertyPath;
  label: string;
  type?: React.HTMLInputTypeAttribute;
  defaultValue?: string;
  notRequired?: boolean;
  initialValue?: Values[string];
  disabled?: boolean;
}

/**
 * @example
 * // fieldProps contains props passed by formik
 * const fieldProps = { values, touched, handleBlur, handleChange, errors }
 * // fieldKey is the current value being referenced in this field
 * // label is the label displayed along with this field
 * <FormField<FormValues> fieldKey="config.client_id" label="Client ID:" {...fieldProps} />
 * @param fieldKey This is used to reference the value in the values object. It can be a string or a path to a nested value.
 * @param label the label displayed along this field
 * @param type optional HTML input type, defaults to text
 * @param notRequired If true, the input element is not required. The prop defaults to the value (false).
 * @returns A generic MUI Input Field including label and errors
 */
export default function FormField<Values extends FormikValues = any>({
  values,
  touched,
  handleBlur,
  handleChange,
  errors,
  fieldKey,
  type,
  notRequired = false,
  initialValue,
  ...fieldProps
}: FormFieldProps<Values>) {
  const notInitial = initialValue && initialValue !== _.get(values, fieldKey);
  const error = _.get(touched, fieldKey) && _.get(errors, fieldKey);
  return (
    <FormControl fullWidth component={motion.div} layout="position">
      <TextField
        name={String(fieldKey)}
        id={String(fieldKey)}
        value={_.get(values, fieldKey)}
        onBlur={handleBlur}
        onChange={handleChange}
        error={Boolean(error)}
        color={notInitial ? 'warning' : 'primary'}
        required={!notRequired}
        fullWidth
        variant="standard"
        type={type || 'text'}
        {...fieldProps}
      />
      <FormHelperText
        component={motion.div}
        layout="position"
        sx={{
          ml: 0,
          color: notInitial ? 'orange' : '',
        }}
        error={Boolean(error)}
      >
        {error ? _.get(errors, fieldKey) : notInitial ? 'This field has been updated' : ''}
      </FormHelperText>
    </FormControl>
  );
}
