export type BMAgent = {
  name: string; //uniqueIdentifier
  displayName: string;
  verified?: boolean;
};

export type BMBrand = {
  name: string;
  displayName: string;
};

export interface BusinessCommunicationsAgentList {
  agents: BMAgent[];
}

export interface BusinessCommunicationsBrandsList {
  brands: BMBrand[];
}

export enum VerificationState {
  UNSPECIFIED = 'VERIFICATION_STATE_UNSPECIFIED',
  UNVERIFIED = 'VERIFICATION_STATE_UNVERIFIED',
  PENDING = 'VERIFICATION_STATE_PENDING',
  VERIFIED = 'VERIFICATION_STATE_VERIFIED',
  SUSPENDED_IN_GMB = 'VERIFICATION_STATE_SUSPENDED_IN_GMB',
}

export interface BusinessVerificationState {
  name: string;
  verificationState: VerificationState;
}

export interface BusinessCommunicationsBrandLocationsList {
  locations: {
    name: string;
  }[];
}
