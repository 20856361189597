import * as React from 'react';
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import useForm from '../../hooks/useForm';
import { drawerWidth } from '../../theme';
import Logo from '../atoms/Logo';

interface DrawerProps extends MuiDrawerProps {
  children: React.ReactNode;
}

export default function Drawer({ children, ...props }: DrawerProps) {
  const { drawerOpen, handleDrawer } = useForm();

  return (
    <>
      <MuiDrawer
        variant="temporary"
        open={drawerOpen}
        onClose={() => handleDrawer(!drawerOpen)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <Toolbar disableGutters>
          <Logo variant="temporary" />
        </Toolbar>
        {children}
      </MuiDrawer>
      <MuiDrawer
        anchor="left"
        variant="permanent"
        open
        sx={{
          height: '100%',
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <Toolbar disableGutters>
          <Logo />
        </Toolbar>
        {children}
      </MuiDrawer>
    </>
  );
}
