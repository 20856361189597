import * as React from 'react';

interface Dialog {
  visible: boolean;
  message: React.ReactNode;
  error: boolean;
  noColor?: boolean;
  title?: string;
  actions?:
    | {
        label: string;
        onClick: React.MouseEventHandler<HTMLButtonElement>;
        color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
      }[]
    | 'none';
  no?: boolean;
}

export const defaultDialog: Dialog = { visible: false, message: '', error: true, noColor: false };

interface DialogContext {
  dialog: Dialog;
  updateDialog(dialog: Dialog): void;
  dialogOpen: boolean;
}

const DialogContext = React.createContext<DialogContext>({
  dialog: defaultDialog,
  updateDialog: () => null,
  dialogOpen: false,
});

export default DialogContext;

export function DialogProvider({ children }: { children: React.ReactNode }) {
  const [dialog, setDialog] = React.useState(defaultDialog);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const updateDialog = React.useMemo(
    () => (newDialog: Dialog) => {
      if (newDialog.visible) {
        setDialog(newDialog);
        setDialogOpen(true);
      } else {
        setDialog({ ...dialog, visible: false });
        setDialogOpen(false);
      }
    },
    [dialog]
  );

  const value = React.useMemo(
    () => ({
      dialog,
      updateDialog,
      dialogOpen,
    }),
    [dialog, dialogOpen, updateDialog]
  );

  return <DialogContext.Provider value={value}>{children}</DialogContext.Provider>;
}
