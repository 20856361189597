import React from 'react';
import { AuthWrapper, Tokens } from '@friendemic/sso-consumer';
import CircularProgress from '@mui/material/CircularProgress';
import CenterBox from './components/atoms/CenterBox';
import { authConsumer } from './utils/auth-consumer';

interface AppInitProps {
  tokens: Tokens | null;
  authError: Error | null;
  children: React.ReactNode;
}

function AppInit({ tokens, authError, children }: AppInitProps) {
  const [authorized, setAuthorized] = React.useState(false);

  React.useEffect(() => {
    if (tokens && tokens.accessToken) {
      setAuthorized(true);
    }
    if (authError) {
      setAuthorized(false);
    }
  }, [authError, tokens]);

  if (authError) {
    return <CenterBox fullScreen>{`Authentication Error: ${authError?.message}`}</CenterBox>;
  }

  if (authorized) {
    return <>{children}</>;
  }
  return (
    <CenterBox fullScreen>
      <CircularProgress size={100} />
    </CenterBox>
  );
}

export default function AppWrapper({ children }: { children: React.ReactNode }) {
  return (
    <div className="App">
      <AuthWrapper consumer={authConsumer}>
        {(tokens, err) => (
          <AppInit tokens={tokens} authError={err}>
            {children}
          </AppInit>
        )}
      </AuthWrapper>
    </div>
  );
}
