import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars as fasBars } from '@fortawesome/pro-solid-svg-icons';
import { faAngleDown as fasAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleUp as fasAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp';
import { faCheckCircle as fasCheckCricle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faClipboard as fasClipboard } from '@fortawesome/pro-solid-svg-icons/faClipboard';
import { faComment as fasComment } from '@fortawesome/pro-solid-svg-icons/faComment';
import { faCopy as fasCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faEdit as fasEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { faEye as fasEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faFileImport as fasFileImport } from '@fortawesome/pro-solid-svg-icons/faFileImport';
import { faPalette as fasPalette } from '@fortawesome/pro-solid-svg-icons/faPalette';
import { faPlusCircle as fasPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faQuoteLeft as faQuoteLeft } from '@fortawesome/pro-solid-svg-icons/faQuoteLeft';
import { faSearch as fasSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faSignOut as fasSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut';
import { faTrash as fasTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faUsersGear as fasUsersGear } from '@fortawesome/pro-solid-svg-icons/faUsersGear';
import { faX as fasX } from '@fortawesome/pro-solid-svg-icons/faX';
import { faXmarkCircle as fasXmarkCircle } from '@fortawesome/pro-solid-svg-icons/faXmarkCircle';

// example use of icon: <FontAwesomeScalableIcon icon={['fas', 'clipboard']} />

export const fontAwesomeConfig = {
  init: (): void => {
    library.add(fasClipboard);
    library.add(fasSignOut);
    library.add(fasBars);
    library.add(fasPalette);
    library.add(fasComment);
    library.add(faQuoteLeft);
    library.add(fasSearch);
    library.add(fasX);
    library.add(fasAngleDown);
    library.add(fasAngleUp);
    library.add(fasUsersGear);
    library.add(fasXmarkCircle);
    library.add(fasCheckCricle);
    library.add(fasEdit);
    library.add(fasEye);
    library.add(fasTrash);
    library.add(fasCopy);
    library.add(fasPlusCircle);
    library.add(fasFileImport);
  },
};
