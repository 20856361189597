import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Slide,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import axios, { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';
import { motion } from 'framer-motion';
import _, { PropertyPath } from 'lodash';
import Yup from 'yup';
import useDialog from '../../hooks/useDialog';
import useTheme from '../../hooks/useTheme';
import CenterBox from '../atoms/CenterBox';
import FontAwesomeScalableIcon from '../atoms/FontAwesomeScalableIcon';
import Modal from '../atoms/Modal';
import Tooltip from '../atoms/Tooltip';
import { Swatch } from '../molecules/ColorPicker';
import Dialog from './Dialog';
import ModifyWidgetForm, { Field } from './ModifyWidgetForm';
import { HighlightedSnippet, Widget } from './WidgetConfigurationTable';

export type Dates = { created_at: string; updated_at: string };

export interface Configs<Resource> {
  configurations: (Resource & Dates)[];
}

export interface HeadCell {
  fieldPath: PropertyPath;
  label: string;
  color?: boolean;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
  image?: boolean;
  boolean?: boolean | { true: string; false: string };
}

interface WidgetTableProps<Config> {
  fields: Field<Config>[];
  headCells: readonly HeadCell[];
  remove?: (keyof Config)[];
  snippet?: (value: Config) => string;
  uidSnippet?: (uid: string) => string;
  validationSchema: Yup.AnySchema;
  widget: Widget;
}

export default function WidgetTable<
  Config extends { dealer_name: string },
  Resource extends { uid: string; config: Config }
>({ fields, headCells, remove, widget, snippet, uidSnippet, validationSchema }: WidgetTableProps<Config>) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [formUid, setFormUid] = React.useState('');
  const [searchList, setSearchList] = React.useState<(Resource & Dates)[]>([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const { updateDialog } = useDialog();

  const queryClient = useQueryClient();
  const { theme } = useTheme();

  const handleOpenView = ({ uid, config }: { uid: string; config: Config }) => {
    updateDialog({
      visible: true,
      title: `Snippet for ${config.dealer_name}`,
      message: uidSnippet ? (
        <HighlightedSnippet snippet={uidSnippet(uid)} />
      ) : (
        snippet && <HighlightedSnippet snippet={snippet(config)} />
      ),
      error: false,
      noColor: true,
      actions: [
        {
          label: 'Copy Snippet',
          onClick: () => {
            navigator.clipboard.writeText(uidSnippet ? uidSnippet(uid) : (snippet && snippet(config)) || '');
          },
        },
      ],
    });
  };

  const handleOpenUpdate = (uid: string) => {
    setFormUid(uid);
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setFormUid('');
    setOpenUpdate(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const params = {
    headers: {
      Authorization: `Bearer ${process.env[`REACT_APP_GLOVEBOX_${widget.toUpperCase()}_API_KEY`]}`,
      'Content-Type': 'application/json',
    },
  };

  const {
    isLoading,
    error,
    data: originalList,
  } = useQuery<any, AxiosError<any>, Configs<Resource>>(`${widget}/configurations`, () =>
    axios.get(`${process.env.REACT_APP_GLOVEBOX_URL}/${widget}/config` as string, params).then((r) => {
      if (r.data.configurations) {
        r.data.configurations.sort(
          (a: Dates, b: Dates) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        );
        let array = r.data.configurations;
        if (searchTerm) {
          array = r.data.configurations.filter((item: Resource) =>
            [item.uid, ...Object.values(item.config)].find((config) =>
              config.toLowerCase().includes(searchTerm.toLowerCase())
            )
          );
        }
        setSearchList(array);
        return r.data;
      }
      const data: any = {};
      data.configurations = [];
      return data;
    })
  );

  React.useEffect(() => {
    setSearchList(originalList?.configurations || []);
  }, [originalList, searchTerm]);

  const requestSearch = (searchTerm: string) => {
    if (!originalList) {
      return;
    }
    setSearchTerm(searchTerm);
    setSearchList(
      originalList.configurations.filter((item) =>
        [item.uid, ...Object.values(item.config)].find((config) =>
          config.toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    );
  };

  async function handleSubmit(values: Config, actions: FormikHelpers<Config>, data: Config, uid?: string) {
    if (!data) {
      updateDialog({
        visible: true,
        message: 'There was an issue updating the widget',
        error: true,
      });
      actions.setSubmitting(false);
      return;
    }
    const newValues: Partial<Config> = { ...values };
    if (remove) {
      for (const val of remove) {
        delete newValues?.[val];
      }
    }
    let key: keyof Config;
    for (key in newValues) {
      if (newValues[key] === data[key]) {
        delete newValues[key];
      }
    }
    const submitValue = {
      config: newValues,
    };

    await axios
      .put(`${process.env.REACT_APP_GLOVEBOX_URL}/${widget}/config/${uid}` as string, submitValue, params)
      .then((r) => r.data)
      .then((data: Resource & { message: string }) => {
        if (data.message) {
          updateDialog({ visible: true, message: data.message, error: true });
        } else {
          updateDialog({
            visible: true,
            message: uidSnippet ? (
              <HighlightedSnippet snippet={uidSnippet(data.uid)} />
            ) : (
              snippet && <HighlightedSnippet snippet={snippet(data.config)} />
            ),
            error: false,
            noColor: true,
            actions: [
              {
                label: 'Copy Snippet',
                onClick: () => {
                  navigator.clipboard.writeText(
                    uidSnippet ? uidSnippet(data.uid) : (snippet && snippet(data.config)) || ''
                  );
                },
              },
            ],
          });
          queryClient.invalidateQueries(`${widget}/configurations`);
          queryClient.invalidateQueries(`config/${formUid}`);
          actions.resetForm();
          handleCloseUpdate();
        }
      })
      .catch((err: AxiosError) => updateDialog({ visible: true, message: err.message, error: true }));
    actions.setSubmitting(false);
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteConfig = async (uid: string) => {
    updateDialog({
      title: 'Are you sure?',
      message: `Are you sure you want to delete the config for ${uid}?`,
      actions: [
        {
          label: 'Yes',
          onClick: async () => {
            await axios
              .delete(`${process.env.REACT_APP_GLOVEBOX_URL}/${widget}/config/${uid}` as string, params)
              .then(() => {
                updateDialog({ visible: true, message: 'Successfully deleted config', error: false });
                queryClient.invalidateQueries(`${widget}/configurations`);
              })
              .catch(() => {
                updateDialog({ visible: true, message: 'Failed to delete config', error: true });
              });
          },
          color: 'error',
        },
      ],
      visible: true,
      error: false,
      noColor: true,
      no: true,
    });
    queryClient.invalidateQueries(`${widget}/configurations`);
  };

  return (
    <>
      <Dialog />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      >
        <Alert severity="info">{'Snippet copied to clipboard'}</Alert>
      </Snackbar>
      <Modal open={openUpdate} onClose={handleCloseUpdate}>
        <ModifyWidgetForm
          uid={formUid}
          onCancel={handleCloseUpdate}
          validationSchema={validationSchema}
          handleSubmit={handleSubmit}
          fields={fields}
          widget={widget}
        />
      </Modal>
      <TextField
        variant="outlined"
        placeholder="Search..."
        type="search"
        disabled={!Boolean(originalList)}
        fullWidth
        onInput={(e: any) => requestSearch(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <FontAwesomeScalableIcon icon={['fas', 'search']} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Toolbar
        sx={{
          pl: { sm: 0 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography variant="h5" id="tableTitle">
          {`${widget
            .split('_')
            .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
            .join(' ')} Configurations`}
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table component={motion.table} sx={{ maxWidth: 1300 }} aria-labelledby="tableTitle">
          <TableHead component={motion.thead}>
            <TableRow component={motion.tr}>
              {headCells.map((headCell, index) => (
                <TableCell key={headCell.label} align={index === 0 ? 'left' : 'center'}>
                  {headCell.label}
                </TableCell>
              ))}
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody component={motion.tbody}>
            {(rowsPerPage > 0
              ? searchList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : searchList
            )?.map((resource) => (
              <TableRow hover component={motion.tr} key={resource.uid}>
                {headCells.map((headCell) => {
                  if (headCell.image) {
                    return (
                      <TableCell key={headCell.label} align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxWidth: 300,
                            gap: 1,
                          }}
                        >
                          <div style={{ width: 80, height: 80 }}>
                            <img
                              src={_.get(resource, headCell.fieldPath)}
                              alt={`${resource.uid}'s Logo`}
                              style={{ display: 'inline', width: 80, height: 80, objectFit: 'contain' }}
                            />
                          </div>
                          <Tooltip title={_.get(resource, headCell.fieldPath)}>
                            <Typography
                              variant="body2"
                              sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                            >
                              {_.get(resource, headCell.fieldPath)}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    );
                  } else if (headCell.color) {
                    return (
                      <TableCell key={headCell.label} align={headCell.align}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                          }}
                        >
                          <Swatch
                            p={2}
                            style={{ display: 'inline', backgroundColor: _.get(resource, headCell.fieldPath) }}
                            notPointer
                            currentColor={_.get(resource, headCell.fieldPath)}
                          />
                          <Tooltip title={_.get(resource, headCell.fieldPath)}>
                            <Typography
                              width={60}
                              variant="body2"
                              align="left"
                              sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                            >
                              {_.get(resource, headCell.fieldPath)}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell key={headCell.label} align={headCell.align || 'left'} sx={{ maxWidth: 150 }}>
                        <Tooltip
                          title={
                            headCell.boolean && typeof headCell.boolean === 'object'
                              ? headCell.boolean[String(_.get(resource, headCell.fieldPath)) as 'true' | 'false']
                              : _.get(resource, headCell.fieldPath)
                          }
                        >
                          <Typography
                            variant="body2"
                            color={
                              headCell.boolean && !(typeof headCell.boolean === 'object')
                                ? _.get(resource, headCell.fieldPath) === 'true'
                                  ? theme.palette.success.main
                                  : theme.palette.error.main
                                : theme.palette.text.primary
                            }
                            sx={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              hover: { overflow: 'visible' },
                            }}
                          >
                            {headCell.boolean && typeof headCell.boolean === 'object'
                              ? headCell.boolean[String(_.get(resource, headCell.fieldPath)) as 'true' | 'false']
                              : _.get(resource, headCell.fieldPath)}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    );
                  }
                })}
                <TableCell align="center">
                  <CenterBox row>
                    <Tooltip title="View Snippet">
                      <IconButton
                        onClick={() => {
                          handleOpenView({ uid: resource.uid, config: resource.config });
                        }}
                      >
                        <FontAwesomeScalableIcon icon={['fas', 'eye']}></FontAwesomeScalableIcon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Copy Snippet">
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(
                            uidSnippet ? uidSnippet(resource.uid) : (snippet && snippet(resource.config)) || ''
                          );
                          setSnackbarOpen(true);
                        }}
                      >
                        <FontAwesomeScalableIcon icon={['fas', 'copy']}></FontAwesomeScalableIcon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Configuration">
                      <IconButton
                        onClick={() => {
                          handleOpenUpdate(resource.uid);
                        }}
                      >
                        <FontAwesomeScalableIcon icon={['fas', 'edit']}></FontAwesomeScalableIcon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Configuration">
                      <IconButton
                        color="error"
                        onClick={() => {
                          handleDeleteConfig(resource.uid);
                        }}
                      >
                        <FontAwesomeScalableIcon
                          iconColor={theme.palette.error.main}
                          icon={['fas', 'trash']}
                        ></FontAwesomeScalableIcon>
                      </IconButton>
                    </Tooltip>
                  </CenterBox>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading ? (
        <CenterBox paddingTop={4}>
          <CircularProgress size={50} />
        </CenterBox>
      ) : error ? (
        <CenterBox paddingTop={4}>{error?.message}</CenterBox>
      ) : (
        <></>
      )}
      <Table>
        <TableFooter component={motion.tfoot}>
          <TableRow component={motion.tr}>
            <TablePagination
              count={searchList?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}
