import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Palette, SxProps, Theme } from '@mui/material/styles';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { motion } from 'framer-motion';
import _, { PropertyPath } from 'lodash';
import useTheme from '../../hooks/useTheme';
import Select, { SelectProps } from '../atoms/Select';

export type Items = string[] | [string, string][];

export interface FormSelectProps<Values extends FormikValues> extends Partial<SelectProps> {
  values: Values;
  touched: FormikTouched<Values>;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: FormikErrors<Values>;
  fieldKey: Extract<keyof Values, string> | PropertyPath;
  validateKey?: Extract<keyof Values, string> | PropertyPath;
  validateArr?: any[] | null;
  label: string;
  type?: React.HTMLInputTypeAttribute;
  defaultValue?: string;
  notRequired?: boolean;
  initialValues?: Values;
  /** Type of select items. In the format [["label1", "value1"], ["label2", "value2"]] or ["label and value 1", "label and value 2"]  */
  items: Items | null | undefined;
  sx?: SxProps<Theme>;
  SelectSx?: SxProps<Theme>;
  HelperSx?: SxProps<Theme>;
}

/**
 * @example
 * // fieldProps contains props passed by formik
 * const fieldProps = { values, touched, handleBlur, handleChange, errors }
 * // fieldKey is the current value being referenced in this field
 * // label is the label displayed with this field
 * <FormSelect<FormValues> fieldKey="client_id" items={[['item1label', 'item1value'], ['item2label', 'item2value']]} label="Client ID:" {...fieldProps} />
 * @param fieldKey This is used to reference the value in the values object. It can be a string or a path to a nested value.
 * @param label the label displayed along this field
 * @param type optional HTML input type, defaults to text
 * @param notRequired If true, the input element is not required. The prop defaults to the value (false).
 * @param items string array containing all select items in the form of "label and value" or [label, value].
 * @returns A generic MUI Input Field including label and errors
 */
export default function FormSelect<Values extends FormikValues = any>({
  values,
  touched,
  handleBlur,
  handleChange,
  errors,
  fieldKey,
  validateKey,
  validateArr,
  label,
  type,
  notRequired = false,
  initialValues,
  items,
  sx,
  SelectSx,
  HelperSx,
  ...props
}: FormSelectProps<Values>) {
  const { theme } = useTheme();
  const notInitial = initialValues && _.get(initialValues, fieldKey) !== _.get(values, fieldKey);
  const error = _.get(touched, fieldKey) && _.get(errors, fieldKey);
  return (
    <FormControl fullWidth component={motion.div} layout="position" sx={sx}>
      <InputLabel sx={{ left: '-15px' }} id={String(fieldKey)}>
        {label}
      </InputLabel>
      <Select
        name={String(fieldKey)}
        id={String(fieldKey)}
        label={label}
        value={_.get(values, fieldKey)}
        onBlur={handleBlur}
        onChange={handleChange}
        color={notInitial ? 'warning' : 'primary'}
        error={Boolean(error)}
        required={!notRequired}
        fullWidth
        type={type || 'text'}
        sx={SelectSx}
        {...props}
      >
        {items?.map((v, i) => {
          let color: keyof Palette | null = null;
          let paletteColor: Palette['success'] | Palette['error'];
          if (validateArr && validateKey) {
            color = _.get(validateArr[i], validateKey) ? 'success' : 'error';
            paletteColor = theme.palette[color];
          }
          return Array.isArray(v) ? (
            <MenuItem
              sx={{
                color: color && paletteColor?.main,
              }}
              value={v[1]}
              key={`${i}-${v[1]}`}
            >
              {v[0]}
            </MenuItem>
          ) : (
            <MenuItem
              sx={{
                color: color && paletteColor?.main,
              }}
              value={v}
              key={`${i}-${v[1]}`}
            >
              {v}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText
        component={motion.p}
        layout="size"
        sx={{
          ml: 0,
          color: notInitial ? 'orange' : '',
          ...HelperSx,
        }}
        error={Boolean(error)}
      >
        {error ? _.get(errors, fieldKey) : notInitial ? 'This field has been updated' : ''}
      </FormHelperText>
    </FormControl>
  );
}
