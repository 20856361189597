import * as React from 'react';
import shortid from 'shortid';
import { Field } from '../components/organisms/ModifyWidgetForm';
import WidgetConfigurationTable from '../components/organisms/WidgetConfigurationTable';
import { HeadCell } from '../components/organisms/WidgetTable';
import { ReviewWidgetSchema } from '../schemas/forms';

export interface Config {
  uid?: string;
  DEBUG: string;
  dealer_name: string;
  profile_id: string;
  theme_bg_color: string;
  theme_text_color: string;
  is_french: string;
  excluded_keywords: string;
}

export interface Resource {
  config: Config;
  uid: string;
}

const initialValues = {
  profile_id: '',
  dealer_name: '',
  theme_bg_color: '#F7F7F7',
  theme_text_color: '#363636',
  is_french: 'false',
  excluded_keywords: '',
  DEBUG: 'true',
};

const fields: Field<Config>[] = [
  { fieldKey: 'dealer_name', label: 'Client Name' },
  {
    fieldKey: 'profile_id',
    label: "Profile ID's (Add Comma seperated values)",
  },
  {
    fieldKey: 'theme_bg_color',
    label: 'Theme Background Color',
    color: true,
  },
  {
    fieldKey: 'theme_text_color',
    label: 'Theme Text Color',
    color: true,
  },
  {
    fieldKey: 'is_french',
    label: 'Language',
    items: [
      ['English', 'false'],
      ['French', 'true'],
    ],
  },
  { fieldKey: 'excluded_keywords', label: 'Excluded Keywords (Add Comma seperated values)', notRequired: true },
  { fieldKey: 'DEBUG', label: 'Debug', items: ['true', 'false'] },
];

const headCells: readonly HeadCell[] = [
  { fieldPath: 'config.dealer_name', label: 'Client Name' },
  { fieldPath: 'uid', label: 'Unique ID', align: 'center' },
  { fieldPath: 'config.profile_id', label: "Profile ID's", align: 'center' },
  { fieldPath: 'config.theme_bg_color', label: 'Theme Background Color', color: true, align: 'center' },
  { fieldPath: 'config.theme_text_color', label: 'Theme Text Color', color: true, align: 'center' },
  { fieldPath: 'config.is_french', label: 'Language', boolean: { true: 'French', false: 'English' }, align: 'center' },
  { fieldPath: 'config.excluded_keywords', label: 'Excluded Keywords', align: 'center' },
  { fieldPath: 'config.DEBUG', label: 'Debug', boolean: true, align: 'center' },
];

const snippet = (uid: string) => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  let folder = '';
  switch (environment) {
    case 'development':
      folder = 'next';
      break;
    case 'staging':
      folder = 'beta';
      break;
    case 'production':
      folder = 'latest';
      break;
    default:
      console.error('environment not found');
  }
  return `<script src="https://reviews.friendemic-tools.com/${folder}/main.js" uid="${uid}"></script>`;
};

export default function ReviewWidgetConfigurationForm() {
  function handleSubmit(values: Config) {
    const submitValue = {
      app_name: 'review_widget',
      uid: values.uid ? values.uid : shortid.generate(),
      config: values,
    };

    return submitValue;
  }

  return (
    <WidgetConfigurationTable<Config, Resource>
      createFields={fields}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      uidSnippet={snippet}
      createValidationSchema={ReviewWidgetSchema}
      widget="review_widget"
      headCells={headCells}
    />
  );
}
