import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import List, { ListProps } from '@mui/material/List';
import styled from '@mui/system/styled';
import useTheme from '../../hooks/useTheme';
import { NavRoute, NavRoutes, nestedNavRoute } from '../../Routes';
import FontAwesomeScalableIcon from '../atoms/FontAwesomeScalableIcon';
import NavItem from './NavItem';

interface NavListProps extends ListProps {
  routes: NavRoutes;
}

const StyledLink = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.common.black,
}));

// Turns any route to a navbar item as a link to that route
const RouteMap = ({ route, sx }: { route: NavRoute; sx?: SxProps }) => (
  <StyledLink underline="none" component={NavLink} to={route.path} key={route.nav}>
    {({ isActive }: { isActive: boolean }) => (
      <NavItem isActive={isActive} title={route.nav} icon={route.icon ? route.icon : null} sx={sx} />
    )}
  </StyledLink>
);

const NestedRoute = ({ nestedRoute }: { nestedRoute: nestedNavRoute }) => {
  const [open, setOpen] = React.useState(true);

  const [title, ...routes] = nestedRoute;

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <NavItem title={title} isActive={false} onClick={handleClick} icon={['fas', 'users-gear']}>
        {open ? (
          <FontAwesomeScalableIcon icon={['fas', 'angle-up']} />
        ) : (
          <FontAwesomeScalableIcon icon={['fas', 'angle-down']} />
        )}
      </NavItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ p: 0 }}>
          {routes.map((route) => (
            <RouteMap key={route.path} route={route} sx={{ pl: 4 }} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default function NavList({ routes, ...props }: NavListProps) {
  const { updateColorMode, isDark } = useTheme();

  const switchTheme = () => {
    updateColorMode(isDark ? 'light' : 'dark');
  };

  return (
    <Box sx={{ display: 'flex', height: '100%', justifyContent: 'space-between', flexDirection: 'column' }}>
      <List {...props}>
        {routes.top.map((route) => {
          if (Array.isArray(route)) {
            return <NestedRoute nestedRoute={route} />;
          }
          return <RouteMap key={route.path} route={route} />;
        })}
      </List>
      <List {...props}>
        {/* <NavItem title={''} isActive={false} onClick={() => handleDrawer(!drawerOpen)} icon={['fas', 'bars']} /> */}
        <NavItem title={'Switch Theme'} isActive={false} onClick={switchTheme} icon={['fas', 'palette']} />
        {routes.bottom.map((route) => (
          <RouteMap key={route.path} route={route} />
        ))}
      </List>
    </Box>
  );
}
