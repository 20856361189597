import * as React from 'react';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';

export interface SelectProps extends MuiSelectProps {
  children: React.ReactNode;
}

export default function Select({ children, ...props }: SelectProps) {
  return (
    <MuiSelect variant="standard" {...props}>
      {children}
    </MuiSelect>
  );
}
