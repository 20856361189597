import * as React from 'react';
import { useCSVReader, formatFileSize, useCSVDownloader } from 'react-papaparse';
import { useQueryClient } from 'react-query';
import {
  Alert,
  CircularProgress,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import styled from '@mui/system/styled';
import axios from 'axios';
// import useTheme from '../../hooks/useTheme';
import { motion } from 'framer-motion';
import Yup, { ValidationError } from 'yup';
import useTheme from '../../hooks/useTheme';
import CenterBox from '../atoms/CenterBox';
import FontAwesomeScalableIcon from '../atoms/FontAwesomeScalableIcon';
import Tooltip from '../atoms/Tooltip';
import { Field } from './ModifyWidgetForm';
import { Widget } from './WidgetConfigurationTable';

const Zone = styled(Box)(({ hover }) => ({
  alignItems: 'center',
  border: '2px dashed #CCC',
  borderRadius: 20,
  borderColor: hover ? '#686868' : '#CCC',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  padding: 20,
  cursor: 'pointer',
}));

const File = styled(Box)(() => ({
  background: 'linear-gradient(to bottom, #EEE, #DDD)',
  borderRadius: 20,
  opacity: 0.8,
  display: 'flex',
  minHeight: 120,
  minWidth: 120,
  position: 'relative',
  zIndex: 10,
  flexDirection: 'column',
  justifyContent: 'center',
  color: '#000000',
}));

const Info = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 10,
  paddingRight: 10,
}));

const Size = styled(Box)(() => ({
  borderRadius: 3,
  marginBottom: '0.5em',
  justifyContent: 'center',
  display: 'flex',
}));

const Name = styled(Box)(() => ({
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  hyphens: 'auto',
  textAlign: 'center',
  borderRadius: 3,
  fontSize: 12,
  marginBottom: '0.5em',
}));

// const RemoveButton = styled(Box)(() => ({
//   height: 20,
//   position: 'absolute',
//   right: 6,
//   top: 6,
//   width: 20,
// }));

type UIDConfig<Config> = Config & { uid?: string };

type PromiseReducerState<Config> = {
  task?: 'add' | 'update';
  status?: 'loading' | 'error' | 'success';
  message?: React.ReactNode;
  value: UIDConfig<Config>;
  newId?: boolean;
};

function promiseReducer<Config extends { dealer_name: string }>(
  state: PromiseReducerState<Config>[],
  action: {
    type: 'clear' | 'update' | 'create' | 'addMessage';
    index?: number;
    payload?: UIDConfig<Config>[] | PromiseReducerState<Config>;
  }
): PromiseReducerState<Config>[] {
  if (Array.isArray(action.payload)) {
    return action.payload.map((config) => ({ status: 'loading', value: config, task: config.uid ? 'update' : 'add' }));
  }
  switch (action.type) {
    case 'clear':
      return [];
    case 'update':
      const newStateUpdate = [...state];
      if (action.index !== undefined && action.payload) {
        if (action.payload.status === 'success' && state[action.index].status === 'error') {
          return newStateUpdate;
        }
        if (action.payload.status === state[action.index].status) {
          newStateUpdate[action.index] = {
            ...state[action.index],
            ...action.payload,
            message: (
              <>
                {state[action.index]?.message}
                <ListItemText disableTypography>{action.payload.message}</ListItemText>
              </>
            ),
          };
        } else if (action.payload.status === 'error' && state[action.index].status === 'success') {
          newStateUpdate[action.index] = {
            ...state[action.index],
            ...action.payload,
            message: (
              <>
                <ListItemText disableTypography>{action.payload.message}</ListItemText>
              </>
            ),
          };
        } else {
          newStateUpdate[action.index] = { ...state[action.index], ...action.payload };
        }
      }
      return newStateUpdate;
    default:
      return state;
  }
}

interface CSVImportProps<Config> {
  onCancel: () => void;
  createFields: Field<Config>[];
  updateFields: Field<Config>[];
  initialValues: Config;
  snippet?: (values: Config) => string;
  uidSnippet?: (uid: string) => string;
  handleSubmit: (values: Config) => void;
  validationSchema: Yup.AnySchema;
  widget: Widget;
}

export default function CSVImport<Config extends { dealer_name: string }>({
  onCancel,
  createFields,
  updateFields,
  initialValues,
  snippet,
  uidSnippet,
  handleSubmit,
  validationSchema,
  widget,
}: CSVImportProps<Config>) {
  const [zoneHover, setZoneHover] = React.useState(false);
  // const [removeHover, setRemoveHover] = React.useState(false);
  // const { theme } = useTheme();
  const [CSVData, setCSVData] = React.useState<null | UIDConfig<Config>[]>(null);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [promiseData, dispatchPromiseData] = React.useReducer(promiseReducer, []);
  const [validating, setValidating] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<boolean>(false);
  // const [success, setSuccess] = React.useState<boolean>(false);
  const queryClient = useQueryClient();
  const { CSVReader } = useCSVReader();
  const { theme } = useTheme();
  const { CSVDownloader, Type } = useCSVDownloader();

  const params = {
    headers: {
      Authorization: `Bearer ${process.env[`REACT_APP_GLOVEBOX_${widget.toUpperCase()}_API_KEY`]}`,
      'Content-Type': 'application/json',
    },
  };

  const disabledUpdateFields = updateFields.filter((field) => field.disabled);

  async function validateData(values: UIDConfig<Config>, index: number) {
    const { uid, ...rest } = values;
    const validation = [true, true];
    if (uid) {
      validation[0] = await axios
        .get(`${process.env.REACT_APP_GLOVEBOX_URL}/${widget}/config/${uid}`, params)
        .then((r) => r.data)
        .then((data: { message?: string; config?: Config }) => {
          if (data.message) {
            dispatchPromiseData({
              type: 'update',
              index,
              payload: {
                status: 'error',
                message: "UID doesn't exist",
                value: values,
              },
            });
            return false;
          }
          const failed = disabledUpdateFields.reduce((status, field) => {
            if (
              rest?.[field.fieldKey as unknown as keyof Omit<UIDConfig<Config>, 'uid'>] !==
              (data?.config?.[field.fieldKey] as any)
            ) {
              dispatchPromiseData({
                type: 'update',
                index,
                payload: {
                  status: 'error',
                  message: `${field.fieldKey} cannot be updated for existing configs`,
                  value: values,
                },
              });
              return false;
            }
            return status;
          }, true);
          return failed;
        })
        .catch(() => {
          dispatchPromiseData({
            type: 'update',
            index,
            payload: {
              status: 'success',
              message: 'Issue finding UID, will create new config',
              value: values,
              newId: true,
            },
          });
          return true;
        });
    }
    validation[1] = await validationSchema
      .validate(rest, { strict: true })
      .then(() => {
        dispatchPromiseData({
          type: 'update',
          index,
          payload: {
            status: 'success',
            message: 'Successfully validated',
            value: values,
          },
        });
        return true;
      })
      .catch((err: ValidationError) => {
        err.errors.forEach((error) => {
          dispatchPromiseData({
            type: 'update',
            index,
            payload: {
              status: 'error',
              message: error,
              value: values,
            },
          });
        });
        return false;
      });
    if (validation.filter((validated) => !validated).length > 0) {
      throw new Error('Error Validating');
    }
  }

  async function submitData(values: UIDConfig<Config>, index: number) {
    const { uid, ...rest } = values;
    if (uid && !promiseData?.[index]?.newId) {
      return axios
        .put(`${process.env.REACT_APP_GLOVEBOX_URL}/${widget}/config/${uid}` as string, { config: rest }, params)
        .then((r) => r.data)
        .then((data: { message?: string }) => {
          if (data.message) {
            dispatchPromiseData({
              type: 'update',
              index,
              payload: { status: 'error', message: 'Config not found', value: values },
            });
            throw new Error(data.message);
          }
          dispatchPromiseData({
            type: 'update',
            index,
            payload: { status: 'success', value: values },
          });
        })
        .catch((err) => {
          dispatchPromiseData({
            type: 'update',
            index,
            payload: { status: 'error', message: 'Config not found', value: values },
          });
          throw new Error(err.message);
        });
    } else {
      const submitValue = handleSubmit(values);
      return axios
        .post(`${process.env.REACT_APP_GLOVEBOX_URL}/${widget}/config` as string, submitValue, params)
        .then((r) => r.data)
        .then((data: { message?: string; uid: string }) => {
          if (data.message) {
            dispatchPromiseData({
              type: 'update',
              index,
              payload: { status: 'error', message: 'Config cannot be created', value: values },
            });
            throw new Error(data.message);
          }
          dispatchPromiseData({
            type: 'update',
            index,
            payload: { status: 'success', value: { ...values, uid: data.uid } },
          });
        })
        .catch((err) => {
          dispatchPromiseData({
            type: 'update',
            index,
            payload: { status: 'error', message: 'Config cannot be created', value: values },
          });
          throw new Error(err.message);
        });
    }
  }

  async function onCSVSubmit() {
    setLoading(true);
    setValidating(true);
    if (CSVData) {
      dispatchPromiseData({ type: 'create', payload: CSVData });
      const promises = await Promise.allSettled(CSVData.map(validateData));
      const errors = promises.filter((p) => p.status === 'rejected');
      if (errors.length > 0) {
        setValidationErrors(true);
      }
      setLoading(false);
    }
  }

  async function onSubmit() {
    setValidating(false);
    if (CSVData) {
      dispatchPromiseData({ type: 'create', payload: CSVData });
      await Promise.allSettled(CSVData.map((data, i) => submitData(data, i)));
    }
    queryClient.invalidateQueries(`${widget}/configurations`);
  }

  if (promiseData.length > 0) {
    return (
      <CenterBox gap={2}>
        <TableContainer
          sx={{
            maxHeight: 600,
          }}
        >
          <Table
            component={motion.table}
            sx={{
              height: 'max-content',
            }}
          >
            <TableHead component={motion.thead}>
              <TableRow component={motion.tr}>
                <TableCell>{'Row'}</TableCell>
                <TableCell></TableCell>
                <TableCell align="center">{'Unique ID'}</TableCell>
                <TableCell align="center">{'Dealer Name'}</TableCell>
                {!validating && <TableCell>{'Copy Snippet'}</TableCell>}
                <TableCell align="center">{'Status'}</TableCell>
                <TableCell>{'Message'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody component={motion.tbody}>
              {promiseData?.map((data, index) => (
                <TableRow hover component={motion.tr} key={`${index}-${data.value.dealer_name}`}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell align="center">
                    <CenterBox>
                      {data.task === 'add' ? (
                        <Tooltip title="New Config">
                          <FontAwesomeScalableIcon icon={['fas', 'plus-circle']} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Updating Config">
                          <FontAwesomeScalableIcon icon={['fas', 'edit']} />
                        </Tooltip>
                      )}
                    </CenterBox>
                  </TableCell>
                  <TableCell align="center">{data.value.uid}</TableCell>
                  <TableCell align="center">{data.value.dealer_name}</TableCell>
                  {!validating && (
                    <TableCell align="center">
                      <Button
                        disabled={data.status !== 'success'}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            uidSnippet && data.value.uid
                              ? uidSnippet(data.value.uid)
                              : (snippet && snippet(data.value as Config)) || ''
                          );
                        }}
                      >
                        {'Copy'}
                      </Button>
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <CenterBox>
                      {data.status === 'success' ? (
                        <FontAwesomeScalableIcon
                          iconColor={theme.palette.success.main}
                          icon={['fas', 'check-circle']}
                        />
                      ) : data.status === 'error' ? (
                        <FontAwesomeScalableIcon iconColor={theme.palette.error.main} icon={['fas', 'xmark-circle']} />
                      ) : data.status === 'loading' ? (
                        <CircularProgress size={24} color="info" />
                      ) : (
                        '-'
                      )}
                    </CenterBox>
                  </TableCell>
                  <TableCell>{data.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {validating && !loading && validationErrors && (
          <Alert severity="error" sx={{ width: '100%' }}>
            {'Errors found in the imported file. Please try again.'}
          </Alert>
        )}
        <CenterBox row gap={1} alignSelf="flex-end">
          {validating && !loading && !validationErrors && <Button onClick={onSubmit}>{'Submit'}</Button>}
          {validating && !loading && validationErrors && (
            <Button
              onClick={() => {
                dispatchPromiseData({ type: 'clear' });
                setValidating(false);
                setLoading(false);
              }}
            >
              {'Try Again'}
            </Button>
          )}
          {!validating && !loading && !validationErrors && (
            <CSVDownloader
              data={promiseData.map(({ value }) => value)}
              type={Type.Link}
              config={{ encoding: 'utf-8' }}
              filename={`${widget}_configurations`}
            >
              <Button>{'Export CSV'}</Button>
            </CSVDownloader>
          )}
          <Button
            onClick={() => {
              dispatchPromiseData({ type: 'clear' });
              onCancel();
            }}
          >
            {'Close'}
          </Button>
        </CenterBox>
      </CenterBox>
    );
  }

  if (loading) {
    return (
      <CenterBox>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <CenterBox gap={2}>
      <CSVDownloader
        data={[
          createFields.reduce<{ [key: string]: string }>(
            (prev, curr) => {
              const value = initialValues?.[curr.fieldKey];
              prev[curr.fieldKey] = (value as unknown as string) || '';
              return prev;
            },
            { uid: 'LEAVE BLANK UNLESS EDITING AN EXISTING CONFIGURATION' }
          ),
        ]}
        type={Type.Link}
        filename={`${widget}_config_template`}
      >
        <Button>{'Download Template'}</Button>
      </CSVDownloader>
      <CSVReader
        onUploadAccepted={({ data }: { data: [(keyof UIDConfig<Config>)[], ...string[]] }) => {
          setCSVData(null);
          setErrors([]);
          const [header, ...rows] = data;
          const filteredRows = rows.filter((row) => row.length === header.length);
          const initialValsArr = [...Object.keys(initialValues), 'uid'];
          const headerTest = header.filter((val) => {
            if (typeof val != 'string') {
              return true;
            }
            if (initialValsArr.includes(val)) {
              initialValsArr.splice(initialValsArr.indexOf(val), 1);
              return false;
            }
            return true;
          });
          if (initialValsArr.length > 0) {
            setErrors([
              ...errors,
              `The following headers are not in the the uploaded file, but required in the template: ${initialValsArr.join(
                ', '
              )}`,
            ]);
          }
          if (headerTest.length > 0) {
            setErrors([
              ...errors,
              `The following headers are in the uploaded file, but do not exist in the template: ${headerTest.join(
                ', '
              )}`,
            ]);
          }
          if (headerTest.length > 0 || initialValsArr.length > 0) {
            return;
          }
          const dataArray = filteredRows.map((row) => {
            const data = header.reduce<any>((prev, curr, index) => {
              if (curr === 'uid') {
                if (prev[curr] === 'LEAVE BLANK UNLESS EDITING AN EXISTING CONFIGURATION') {
                  return prev;
                }
                prev[curr] = row[index] || '';
                return prev;
              }
              let value = row[index] || initialValues?.[curr];
              if (value === 'TRUE') {
                value = 'true';
              }
              if (value === 'FALSE') {
                value = 'false';
              }
              prev[curr] = value;
              return prev;
            }, {});
            return data;
          });
          setCSVData(dataArray);
          setZoneHover(false);
        }}
        onDragOver={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({ getRootProps, acceptedFile }: any) => (
          <Zone hover={zoneHover} {...getRootProps()}>
            {CSVData && acceptedFile ? (
              <File>
                <Info>
                  <Size component="span">{formatFileSize(acceptedFile.size)}</Size>
                  <Name>{acceptedFile.name}</Name>
                </Info>
                {/* <RemoveButton
                  {...getRemoveFileProps()}
                  onMouseOver={(event: Event) => {
                    event.preventDefault();
                    setRemoveHover(true);
                  }}
                  onMouseOut={(event: Event) => {
                    event.preventDefault();
                    setRemoveHover(false);
                  }}
                >
                  <Remove color={removeHover ? theme.palette.error.light : theme.palette.error.main} />
                </RemoveButton> */}
              </File>
            ) : (
              'Drop CSV file here or click to upload'
            )}
          </Zone>
        )}
      </CSVReader>
      {errors.map((currError) => (
        <Alert key={currError} severity="error">
          {currError}
        </Alert>
      ))}
      <CenterBox gap={2} row>
        <Button onClick={onCSVSubmit} disabled={Boolean(!CSVData) || loading}>
          {'Upload'}
        </Button>
        <Button
          onClick={() => {
            dispatchPromiseData({ type: 'clear' });
            onCancel();
          }}
        >
          {'Cancel'}
        </Button>
      </CenterBox>
    </CenterBox>
  );
}
