import React from 'react';
import { Box, DrawerProps } from '@mui/material';
import useTheme from '../../hooks/useTheme';

export default function Logo(props: { variant?: DrawerProps['variant'] }) {
  const { variant } = props;
  const { theme, isDark } = useTheme();

  const black = isDark
    ? variant === 'temporary'
      ? theme.palette.grey['800']
      : theme.palette.background.default
    : theme.palette.text.primary;

  const primary = isDark ? '#ffffff' : black;
  const secondary = isDark ? black : '#ffffff';

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        maxHeight: '76px',
        minHeight: '76px',
        paddingTop: 1.5,
        display: 'flex',
        alignItems: 'center',
        ['svg']: {
          width: '100%',
          height: '80%',
        },
      }}
    >
      <svg viewBox="0 0 1168.32 269.84" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={primary}
          d="m884.5 143c-13.3-15.11-38.39 15.56-44.58 27.24-3.16 5.53-5.74 11.32-6.78 17.64-.35 2.3-1.43 3.99-3.11 5.46-2.67 1.89-11.29 12.31-12.36 4.84 1.88-16.53 9.51-31.92 14.73-47.6 1.54-5-10.46-7.7-12.04-3.04-10.99 20.73-34.34 94.55 12.62 52.76 2.55 12.86 15.05 17.77 26.18 11.39 6.32-3.49 24.44-14.07 22.23-22.06-7.98 3.8-33.53 29.26-35.67 6.97 0-17.09 12.73-41.87 29.48-47.4 1.32 4.52-4.08 10.86 1.02 14.47 6.49 5.96 9.46-18.22 8.27-20.66z"
        />
        <path
          fill={primary}
          d="m617.16 203.92c9.75 21.39 29.52 4.29 37.76-8.7 2.35 10.11-2.02 26.05 9.31 31.3 2.44.95 3.27.26 2.87-2.34-3.76-24.62-1.01-49.83 5.66-73.72 7.08-25.22 17.82-48.83 31.8-70.99 3.77-5.98 7.47-12.04 12.81-16.9 5.42-5.16-2.34-11.93-7.83-12.5-22.76 24.88-36.35 58.05-46.96 89.73-20.34-40.48-50.27 31.94-47.67 51.73-1.4 3.51-15.27 15.46-17.3 8.33-.58-15.97 8.51-30.94 13.92-45.61-2.55-3.1-8.93-7.71-11.73-3.08-8.23 11.04-15.72 22.65-24.74 33.09-5.57 6.32-11.15 11.96-7.96-1.39 2.86-11.04 7.14-21.74 12.36-31.88 1.15-2.56 3.14-4.48 4.85-6.65 4.2-6.69-5.13-12.07-11.28-11.02-7.42 14.62-12.2 30.72-19.69 45.21-7.79 9.13-33.9 40.44-36.25 12.02 63.88-46.32 2.85-82.74-11.51-19.08-2.68 15.12 1.65 15.05-11.82 25.53-27.51 21.83-1.41-31.52 3.86-39.28 4.52-5.91-2.37-10.25-8.03-9.37-2.96.12-2.64.03-4.27 2.34-11.33 16-22.27 32.38-35.96 46.52-32.3 30.6-3.55-32.87 6.4-40.63 9.83 3.61 19.3-9.16 11.39-16.34-5.05-5.04-12.52-9.66-15.48-15.95 3.27-7.5 17.04-41.87.67-39.88-11.65 5.59-26.03 13.7-29.48 27.06-.58 8.51 7.43 14.57 10.97 21.75-14.71 25.18-35.03 47.88-58.85 64.76-7.38-6.65-17.42-1.16-18.19 6.27-1.55 9.3 10.4 14.97 18.19 10.34 10.21-5.89 17.64-15.6 25.95-23.79 14.69-15.03 27.58-32.17 40.57-48.3 1.62 1.65 3.37 3.27 4.79 5.24-38.53 47.63-27.67 111.75 26.98 36.11-8.41 40.56 7.08 36.94 32.08 13.65 10.62 34.68 30.81 10.53 46.3-3.9-7.64 40.49 21.8 4.63 33.56-4.53-6.39 30.67 12.55 27.24 30.96 12.37.38.94.68 1.7.98 2.46zm11.18-30.36c3.12-11.99 7.32-26.44 18.44-33.26 8.7-2.47 10.98 10.26 12.3 16.41-.58 5.36-1.56 10.93-2.98 16.17-4.68 9.7-8.99 21.88-19.19 26.93-11.96.57-10.07-18.26-8.57-26.25zm-110.71 3.94c6.97-49.79 39.38-25.14.09 1.08-.03-.32-.14-.71-.09-1.08zm-71.36-61.26c-.09.46-.34.9-.53 1.39-2.12-2.83-9.49-8.76-5.53-12.15 10.85-10.04 7.44 4.11 6.05 10.76z"
        />
        <path
          fill={primary}
          d="m367.96 135.93c6.7-8.71 37.53-9.49 48.61-11.43-2.58-6.44-4.98-12.73-9.46-17.98-10.86.56-20.91 5.28-31.17 7.72 12.79-31.24 28.19-62.95 52.71-86.69 16.04-13.22 18.01.17 11.27 13.55-5.87 14.24-16.08 26.24-23.95 39.11 2.9 2.44 9.01 5.73 11.71 2 13.89-18.21 28.73-37.87 31.93-61.15 2.91-18.73-14.14-25.81-29.26-17.82-15.44 7.19-26.87 20.71-35.38 35.12-10.67 18.25-19.92 37.19-28.13 56.65-3.83 9.06-7.47 18.2-10.76 27.47-1.59 4.25-6.75 5.08-10.05 7.7-21.3 13.53-41.71 29.28-58.93 47.59 2.64 4.57 6.36 8.08 10.72 11.24 15.88-15.63 31.24-32.19 50.24-44.11-11.14 37.9-21.33 76.46-26.45 115.7 5.5 5.29 14.55 9.52 19.77 9.21 3.05-45.58 12.13-90.56 26.57-133.89z"
        />
        <path
          fill={primary}
          d="m803.59 155.8c-8.07-11.01-14.59 4.84-20.52 10.02-7.61 8.21-14.46 17.07-22.18 25.09-.55-8.64 18.51-32.17 12.63-37.33-4.36-5.62-11.53-5.39-15.62.4-9.01 10.07-17.59 20.37-26.75 30.31 4.74-13.23 12.55-25.64 18.37-38.59 4.52-7.15 4.31-10.01-3.42-14.23-12.09-9.98-27.55 49.68-32.93 57.35-5.75 5.68-18.31 18.66-25.31 8.64-1.1-3.05-3.13-7.69.08-10.1 4.77-3.55 9.21-7.48 13.33-11.77 11.29-9.39 24.99-39.35.34-39.49-37.06 4.82-42.27 111 9.1 65.48-2.69 37.35 21.33-7.55 28.8-15.89 5.26-6.53 10.23-13.35 15.85-19.57-1.05 7.37-22.21 55.08-.43 45.31 10.46-12.27 19.58-25.95 30.79-37.71-4.62 12.68-8.94 27.03-5.23 40.73 3.37 12.22 16.1 7.23 20.67-1.31.32-.4.26-1.24.03-1.58-1.47-1.01-2.9.89-3.87 1.8-9.97 9.38-10.91-7.81-7.65-14.07 4.03-10.98 9.06-21.51 14.35-31.92 2.18-3.68 2.78-8.29-.42-11.58zm-116 17.55c10.48-48.86 33.52-16.81-.73 5.86 0-2.29.41-4.07.73-5.86z"
        />
        <path fill={primary} d="m498.5 138.09c10.42.86 15.42-17.68 3.3-18.18-8.79-.44-13.72 17.39-3.3 18.18z" />
        <path fill={primary} d="m830.05 134.39c9.04-.87 13.09-16.52 2.65-18.11-10.27-1.57-15.02 19.71-2.65 18.11z" />
        <path
          fill={primary}
          d="m0 134.97c.07-74.4 60.19-134.97 135.36-134.95 76.36.01 136.84 62.38 135.32 137.76-1.51 75.01-64.87 135.08-141.08 131.94-69.59-2.86-129.36-59.91-129.6-134.75z"
        />
        <path
          fill={secondary}
          d="m71.58 133.05c-.72.59-1.51 1.11-2.13 1.78-7.23 7.7-8.66 16.86-7.29 26.99.77 5.68 1.18 11.52.76 17.22-.67 9.15-6.15 15.04-14.99 17.4-2.85.76-4.08 2.42-3.92 5.51.16 3.15 1.66 5.72 4.54 6.84 2.56 1 5.45 1.84 8.13 1.68 14-.81 26.58-4.87 35.52-16.62 3.12-4.1 7.48-6.37 12.59-6.21 7.02.21 14.03.9 21.03 1.53 11.34 1.01 21.27 5.31 29.93 12.73 3.01 2.59 6.26 5.02 9.71 6.97 5.97 3.37 12.01 6.71 18.34 9.28 5.08 2.06 9.95 0 12.84-4.14 1.89-2.72 1.92-5.29-.38-7.64-1.84-1.88-3.98-3.51-6.12-5.07-3.22-2.34-6.63-4.43-9.83-6.81-2.67-1.99-4.76-4.51-5.66-7.83-1.46-5.44 1.12-9.35 6.68-10.01 3.62-.43 6.81.77 9.65 2.88 2.57 1.91 5 4.01 7.54 5.97 2.82 2.16 5.97 3.35 9.6 3.08 5.97-.44 8.95-5.17 6.43-10.57-.84-1.8-1.97-3.68-3.47-4.92-3.78-3.13-7.86-5.89-11.79-8.84-1.93-1.45-3.99-2.8-5.61-4.55-2.49-2.68-2.21-5.25 1.03-6.91 2.85-1.47 6.08-2.53 9.25-3.03 10.2-1.61 18.54-6.05 24.16-14.87 5.81-9.11 5.22-14.33-1.94-22.49-12.03-13.72-41.93-21.61-60.02-3.97-.47-.71-.83-1.53-1.43-2.13-11.19-11.15-22.24-22.43-33.66-33.35-10.9-10.42-22.17-20.46-33.33-30.61-.72-.66-2.08-1.39-2.75-1.11-.72.3-1.23 1.68-1.39 2.67-.53 3.31-1.02 6.64-1.25 9.98-1.03 14.37 1.4 28.02 9.15 40.38 9.75 15.55 24.24 24.4 41.79 28.71 4.05 1 8.22 1.54 12.34 2.29-2.82 5.67-11.39 13.93-16.95 16.34-4.41 1.92-8.91 2.04-13.19-.06-3.28-1.61-6.33-3.69-9.38-5.72-3.12-2.07-6.07-4.42-9.19-6.5-5.34-3.56-11.25-5.07-17.64-4.07-3.34.52-6.62 1.38-9.88 2.08 3.99-7.57 3.25-14.62-2.08-19.38-4.33-3.86-10.48-4.34-15.19-1.19-5.31 3.54-7.57 10.61-5.37 16.8 2.23 6.27 7.66 9.68 14.82 9.5z"
        />
        <path
          fill={primary}
          d="m202.16 123.67c-.05-3.68.95-6.83 2.02-9.95.57-1.67 1.8-3.15 3.92-3.24 2.24-.09 3.48 1.39 4.29 3.1 3.19 6.72 3.37 13.58.18 20.3-.66 1.4-2.58 3.01-3.94 3.04-1.35.02-3.4-1.55-3.99-2.91-1.41-3.3-2.44-6.75-2.48-10.34z"
        />
        <path
          fill={primary}
          d="m1013.45 154.13-.8 1.75c-2.57 5.67-5.47 12.09-15.77 15.13l-.03-.54c1.12-6.11 1.84-19.4 1.38-22.25-1.38-8.42-5.57-12.71-12.46-12.75h-.11c-13.5 0-28.01 15.19-35.33 37.02-1.21 3.89-2.06 7.07-2.67 10.02-1.07 5.77-1.26 11.44-.52 15.97 1.69 10.34 8.15 16.07 17.92 16.07.97 0 1.98-.06 3.01-.17 13.58-1.5 21.24-11.45 26.38-34.31l2.68-.44.11-.03c6.46-2.7 12.75-8.89 19.23-18.91l.16-.24-3.2-6.32zm-33.08 12.08c-1.27 6.38 2.47 9.61 4.94 11.75.34.29.66.57.94.84-3.21 15.75-7.07 23-12.88 24.2-2.77.58-6.72-.34-9.38-2.17-1.16-.8-2.6-2.13-2.9-3.94-1.63-9.98 1.25-19.64 4.6-28.6 4.62-12.35 14.03-22.86 20.24-22.86.26 0 .52.02.77.06 2.05.31 4.22 2.09 4.17 6.12v1.26c.02 1.5.03 2.71-.46 4.47l-.25.04c-4.28.7-8.32 1.36-9.81 8.84z"
        />
        <path
          fill={primary}
          d="m1071.34 155.55-.87-.43c-.09.11-8.87 10.74-19.04 15.77l-.02-.42c1.12-6.11 1.84-19.4 1.38-22.25-1.38-8.42-5.57-12.71-12.46-12.75h-.11c-13.5 0-28.01 15.19-35.33 37.02-1.21 3.89-2.06 7.07-2.67 10.02-1.07 5.77-1.26 11.44-.52 15.97 1.69 10.34 8.15 16.07 17.92 16.07.97 0 1.98-.06 3.01-.17 13.57-1.5 21.22-11.43 26.37-34.24.68-.02 2.03-.17 3.37-1.02 8.34-5.25 18.07-12.21 18.07-14.51v-.13l-.06-.11c-.5-.9.26-5.7.96-8.81zm-36.43 10.66c-1.27 6.38 2.47 9.61 4.94 11.75.34.29.66.57.94.84-3.21 15.75-7.07 23-12.88 24.2-2.77.58-6.71-.34-9.38-2.17-1.16-.8-2.6-2.13-2.9-3.95-1.63-9.98 1.25-19.64 4.6-28.6 4.62-12.35 14.03-22.86 20.24-22.86.26 0 .52.02.77.06 2.05.31 4.22 2.09 4.17 6.12v1.26c.02 1.5.03 2.71-.46 4.47l-.25.04c-4.28.7-8.32 1.36-9.81 8.84z"
        />
        <path
          fill={primary}
          d="m968.32 44.4-.3-.02-.16.26c-6.5 10.63-12.44 22.51-19.26 38.51-15.4 36.12-24.07 69.16-25.76 98.19-.95 16.28 2.58 27.01 10.2 31.03 2.66 1.4 5.5 2 8.37 2 6.9 0 13.91-3.44 18.74-7.28.98-.78.98-1.31.89-1.82-.04-.25-.08-.41.38-.86 1.38-1.38 1.83-2.39 1.38-3.1-.63-.99-2.64-.53-4.21.04-1.51.55-2.61 1.3-3.5 1.91-.66.45-1.23.84-1.78 1.02-.41.13-.94.35-1.55.61-2.75 1.16-7.36 3.1-10.73.95-2.73-1.74-4.23-5.89-4.46-12.35-1.16-31.54 16.11-88.71 40.16-132.97.37-.69.74-1.28 1.1-1.85 1.23-1.97 2.29-3.67 2.29-7.34 0-4.07-3.96-6.4-11.77-6.92z"
        />
        <path
          fill={primary}
          d="m982.83 101.76c-10.24-3.84-37.23.89-57.42 7.29-2.91.92-28.63 9.59-28.88 11.53-.22 1.71-.39 4.08.67 4.98.21.18.54.35 1 .35.19 0 .41-.03.65-.1 4.72-1.42 8.84-2.69 12.52-3.81 10.75-3.29 17.85-5.47 24.54-6.91 10.94-2.36 26.63-3.41 35.49-3.05 3.65.15 6.48.92 8.76 1.55 2.11.58 3.63 1 4.84.64.82-.24 1.27-1.18 1.33-2.79.11-3.18-1.44-8.9-3.5-9.67z"
        />
        <path
          fill={primary}
          d="m1072.53 166.58c37.27-30.72 63.28-83.2 68.33-103.21 1.32-5 1.85-9.29 1.55-12.41-.35-3.6-1.58-6.33-3.67-8.11-2-1.7-4.74-2.45-7.94-2.14-15.82 1.52-48.06 31.83-67.2 106.71-4.84 18.72-6.8 34.83-5.67 46.61.69 7.13 2.44 12.55 5.2 16.11 2.5 3.22 5.75 4.85 9.68 4.85.47 0 .96-.02 1.45-.07 6.1-.59 14.95-5.9 19.33-14.59l.04-.11c1.18-5.2 1.11-8.2-.21-9.17-.72-.53-1.58-.29-1.94-.08-.15.07-1.65.94-8.38 9.27-2.76 3.41-5.46 4.71-7.82 3.76-4.2-1.7-7.12-10.42-6.36-19.04.5-5.69.97-11.07 3.59-18.37zm2.45-11.51c20.88-63.85 52.58-100.41 55.71-101.55.4-.15.71-.2.95-.2.5 0 .7.23.79.32 2.26 2.47-2.7 16.8-6 23.8-13.49 28.63-35.81 64.96-51.45 77.63z"
        />
        <path
          fill={primary}
          d="m1156.57 176.73-.31.61c-3.19 6.26-6.58 10.9-10.1 13.79-3.27 2.69-6.68 4.49-10.14 5.36.18-.97.33-2.01.47-3.09.17-1.4.26-2.82.26-4.22 0-5.34-.79-10.55-2.36-15.49-1.56-4.91-3.14-9.64-4.68-14.05-1.53-4.37-2.31-8.43-2.31-12.05 0-1.79.17-3.26.5-4.36.36-1.2.54-2.18.54-3-.13-.79-.5-1.5-1.09-2.09-.61-.61-1.52-.93-2.69-.93-1.88 0-3.65 1-5.24 2.96s-2.37 5.12-2.37 9.67v1.8c-2.16 4.66-4.73 9.05-7.62 13.07-2.91 4.05-6.01 7.85-9.22 11.29-.53.53-1.37 1.01-2.51 1.45-1.25.48-2.56 1.16-3.91 2.04-1.4.91-2.59 2.35-3.55 4.27-.96 1.91-1.44 4.67-1.44 8.19s.71 7.28 2.11 10.84c1.41 3.6 3.83 6.64 7.19 9.06 3.37 2.42 7.92 3.65 13.55 3.65 5.14 0 9.83-1.19 13.94-3.53 4.05-2.32 7.07-5.93 8.95-10.73 5.27-.96 10.12-3.23 14.42-6.73 4.38-3.56 7.73-8.62 9.95-15.04l.1-.3zm-53.66 4.05c2.59-2.62 5.31-5.93 8.11-9.85 2.63-3.69 5.1-7.41 7.34-11.09.98 4.49 2.08 9.2 3.29 14.03 1.31 5.26 1.98 10.58 1.98 15.82 0 1.35-.06 2.64-.17 3.85-.09.99-.23 1.94-.41 2.82-4.77-1.16-8.9-3.23-12.28-6.18-3.41-2.97-6.05-6.13-7.86-9.41zm18.82 20.04c-1.09 2.9-2.63 4.8-4.56 5.67-2.13.95-4.04 1.43-5.69 1.43-4.25 0-7.46-1.63-9.81-4.98-2.35-3.36-3.55-7.06-3.55-10.98 0-4.38 1.8-4.93 2.71-5.03 1.87 2.73 4.71 5.52 8.45 8.29 3.7 2.75 7.89 4.63 12.45 5.6z"
        />
      </svg>
    </Box>
  );
}
