import * as React from 'react';
import { Field } from '../components/organisms/ModifyWidgetForm';
import WidgetConfigurationTable from '../components/organisms/WidgetConfigurationTable';
import { HeadCell } from '../components/organisms/WidgetTable';
import { ChatWidgetSchema } from '../schemas/forms';

export interface Config {
  client_id: string;
  app_id: string;
  sms_chat_reply_number: string;
  dealer_name: string;
  dealer_code: string;
  header_logo: string;
  theme_bg_color: string;
  theme_text_color: string;
  DEBUG: string;
}

export interface Resource {
  uid: string;
  client_name: string;
  config: Config;
}

const fields: Field<Config>[] = [
  {
    fieldKey: 'app_id',
    label: 'App ID',
    items: [
      ['Production', 'DED6FF3E-89E3-40BF-8F1F-32B069008DE8'],
      ['Staging', '70C5B4F2-A7CF-4BD6-81C4-68841DE927CC'],
    ],
  },
  { fieldKey: 'sms_chat_reply_number', label: 'SMS Chat Reply Number', notRequired: true },
  { fieldKey: 'dealer_name', label: 'Dealer Name' },
  { fieldKey: 'dealer_code', label: 'Dealer Code', notRequired: true },
  { fieldKey: 'header_logo', label: 'Header Logo' },
  { fieldKey: 'theme_bg_color', label: 'Theme Background Color', color: true },
  { fieldKey: 'theme_text_color', label: 'Theme Text Color', color: true },
  { fieldKey: 'DEBUG', label: 'DEBUG', items: ['true', 'false'] },
];

const client_id: Field<Config> = {
  fieldKey: 'client_id',
  label: 'Client ID',
  placeholder: "Normally the dealer's Place ULID",
};

const createFields: Field<Config>[] = [client_id, ...fields];

const updateFields: Field<Config>[] = [{ disabled: true, ...client_id }, ...fields];

const headCells: readonly HeadCell[] = [
  {
    fieldPath: 'client_name',
    label: 'Client Name',
  },
  {
    fieldPath: 'config.client_id',
    label: 'Client ID',
    align: 'center',
  },
  { fieldPath: 'config.sms_chat_reply_number', label: 'SMS Chat Reply Number' },
  { fieldPath: 'config.header_logo', label: 'Header Logo', image: true },
  {
    fieldPath: 'config.theme_bg_color',
    label: 'Theme Background Color',
    color: true,
  },
  {
    fieldPath: 'config.theme_text_color',
    label: 'Theme Text Color',
    color: true,
  },
  {
    fieldPath: 'config.DEBUG',
    label: 'Debug',
    boolean: true,
  },
];

const snippet = ({ client_id }: Config) => `<!DOCTYPE html>
  <html lang="en">
    <head>
      <link rel="stylesheet" href="https://unpkg.com/@friendemic/catalyst-chat-widget@latest/build/static/css/main.css" />
    </head>
    <body>
    <div id="friendemic_chat_widget" data-client-id="${client_id}"></div>
      <script src="https://unpkg.com/@friendemic/catalyst-chat-widget@next/build/static/js/main.js"></script>
    </body>
  </html>`;

export default function ChatWidgetConfigurationForm() {
  function handleSubmit(values: Config) {
    const submitValue = {
      app_name: 'chat_widget',
      uid: values.client_id,
      client_name: values.dealer_name,
      config: values,
    };

    return submitValue;
  }

  return (
    <WidgetConfigurationTable<Config, Resource>
      createFields={createFields}
      updateFields={updateFields}
      handleSubmit={handleSubmit}
      initialValues={{
        client_id: '',
        app_id: 'DED6FF3E-89E3-40BF-8F1F-32B069008DE8',
        sms_chat_reply_number: '',
        dealer_name: '',
        dealer_code: '',
        header_logo: '',
        theme_bg_color: '#F7F7F7',
        theme_text_color: '#363636',
        DEBUG: 'true',
      }}
      snippet={snippet}
      createValidationSchema={ChatWidgetSchema}
      widget="chat_widget"
      headCells={headCells}
      remove={['client_id']}
    />
  );
}
