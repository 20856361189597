import * as React from 'react';
import { HexColorPicker } from 'react-colorful';
import { Box } from '@mui/material';
import styled from '@mui/system/styled';
import { hasBadContrast } from 'color2k';
import { motion } from 'framer-motion';
import useClickOutside from '../../hooks/useClickOutside';

interface ColorPickerProps {
  color: string;
  onChange: (newColor: string) => void;
}

const PopUp = styled(Box)(() => ({
  position: 'relative',
  zIndex: 2,
  top: -95,
  right: 'calc(100% - 50px)',
  borderRadius: '9px',
  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
}));

export const Swatch = styled(Box)(({ theme, notPointer, currentColor }) => ({
  position: 'relative',
  zIndex: 1,
  width: '28px',
  height: '28px',
  borderRadius: '8px',
  border: `3px solid ${
    currentColor
      ? hasBadContrast(theme.palette.common.black, 'decorative', currentColor)
        ? theme.palette.common.white
        : theme.palette.common.black
      : theme.palette.common.black
  }`,
  boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)',
  cursor: !notPointer ? 'pointer' : 'default',
}));

export default function ColorPicker({ color, onChange }: ColorPickerProps) {
  const popover = React.useRef();
  const [isOpen, toggle] = React.useState(false);

  const close = React.useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <Box
      component={motion.div}
      layout="position"
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      <Swatch p={2} style={{ backgroundColor: color }} onClick={() => toggle(true)}>
        {isOpen && (
          <PopUp ref={popover}>
            <HexColorPicker color={color} onChange={onChange} />
          </PopUp>
        )}
      </Swatch>
    </Box>
  );
}
