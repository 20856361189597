import * as React from 'react';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import ThemeContext from '../contexts/Theme';

export default function useTheme() {
  const muiTheme = useMuiTheme();
  const appTheme = React.useContext(ThemeContext);

  return {
    theme: muiTheme,
    ...appTheme,
  };
}
