export class BasicOAuthAPIClient {
  _baseURL = '';
  _oAuthKey = '';

  // base URL should not have a trailing slash
  constructor(baseURL: string, OAuthKey: string) {
    this._baseURL = baseURL;
    this._oAuthKey = OAuthKey;
  }

  createURL(path: string) {
    return this._baseURL + path;
  }

  createRequestHeaders() {
    const h = new Headers();
    h.set('Content-Type', 'application-json');
    h.set('Authorization', `Bearer ${this._oAuthKey}`);
    return h;
  }

  async get(path: string) {
    return await fetch(this.createURL(path), { headers: this.createRequestHeaders() });
  }

  async post(path: string, body?: BodyInit | null) {
    return await fetch(this.createURL(path), { method: 'POST', headers: this.createRequestHeaders(), body });
  }
}
