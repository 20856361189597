import * as React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import useTheme from '../../hooks/useTheme';

interface FontAwesomeScalableIconProps extends SvgIconProps {
  icon: IconProp;
  iconColor?: string;
}

export default function FontAwesomeScalableIcon({ icon, iconColor, ...props }: FontAwesomeScalableIconProps) {
  const { theme } = useTheme();
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 20" preserveAspectRatio="xMidYMid meet">
        <FontAwesomeIcon color={iconColor || theme.palette.primary.main} icon={icon} />
      </svg>
    </SvgIcon>
  );
}
