import { FormValues } from '../../views/BusinessMessageAgent/BMAForm';
import { BasicOAuthAPIClient } from '../basicOAuthAPIClient';
import {
  BMAgent,
  BusinessCommunicationsAgentList,
  BusinessCommunicationsBrandLocationsList,
  BusinessCommunicationsBrandsList,
  BusinessVerificationState,
  VerificationState,
} from './types';

export class BusinessCommunicationsAPI extends BasicOAuthAPIClient {
  Agent_Name_To_Display_Name: { [key: string]: string } = {};

  Get_Agent_Display_Name(agentName: string) {
    return this.Agent_Name_To_Display_Name[agentName];
  }

  async Account_Agents_List() {
    const brandsList = await this.Account_Brands_List();
    const agentsList: BMAgent[] = [];

    brandsList.forEach(async (brand) => {
      const response = await this.get(`/${brand.name}/agents`);
      const allAgents: BusinessCommunicationsAgentList = await response.json();
      allAgents.agents.forEach(async (agent) => {
        this.Agent_Name_To_Display_Name[agent.name] = agent.displayName;
        agent.verified = (await this.Get_Agent_Verification_State(agent.name)) === VerificationState.VERIFIED;
        agentsList.push(agent);
      });
    });
    // await Promise.all(
    //   brandsList.map(async (brand) => {
    //     const response = await this.get(`/${brand.name}/agents`);
    //     const allAgents: BusinessCommunicationsAgentList = await response.json();
    //     return allAgents.agents;
    //   })
    // );
    return agentsList;
  }

  async Account_Brands_List() {
    const response = await this.get('/brands');
    const allBrands: BusinessCommunicationsBrandsList = await response.json();
    return allBrands.brands;
  }

  async Get_Agent_Verification_State(agentName: string) {
    const response = await this.get(`/${agentName}/verification`);
    const verificationState: BusinessVerificationState = await response.json();
    return verificationState.verificationState;
  }

  async Verify_Agent(agentVerificationContact: FormValues) {
    const { agent, brand, partner } = agentVerificationContact;

    const response = await this.post(
      `/${agent}:requestVerification`,
      JSON.stringify({
        agentVerificationContact: {
          partnerName: partner.name,
          partnerEmailAddress: partner.email,
          brandContactName: brand.name,
          brandContactEmailAddress: brand.email,
          brandWebsiteUrl: brand.url,
        },
      })
    );

    const agentVerification: BusinessVerificationState = await response.json();

    return agentVerification.verificationState;
  }

  async Get_Brand_Locations(agentName: string) {
    const response = await this.get(`/${agentName.substring(0, agentName.indexOf('/agents'))}/locations`);
    const locationsList: BusinessCommunicationsBrandLocationsList = await response.json();

    return locationsList.locations;
  }

  async Get_Location_Verification_State(locationName: string) {
    const response = await this.get(`/${locationName}/verification`);
    const locationVerification: BusinessVerificationState = await response.json();

    return locationVerification;
  }

  async Verify_Location(locationName: string) {
    const response = await this.post(`/${locationName}:requestVerification`);

    const locationVerification: BusinessVerificationState = await response.json();

    return locationVerification.verificationState;
  }
}
