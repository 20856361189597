import * as React from 'react';
import { useCSVDownloader } from 'react-papaparse';
import { useQuery, useQueryClient } from 'react-query';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { Box, Button } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';
import { materialDark, materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Yup from 'yup';
import useDialog from '../../hooks/useDialog';
import useForm from '../../hooks/useForm';
import useTheme from '../../hooks/useTheme';
import FormPaper from '../atoms/FormPaper';
import Modal from '../atoms/Modal';
import CSVImport from './CSVImport';
import ModifyWidgetForm, { Field } from './ModifyWidgetForm';
import WidgetTable, { Configs, HeadCell } from './WidgetTable';

export const HighlightedSnippet = ({ snippet }: { snippet: string }) => {
  const { isDark } = useTheme();

  return (
    <SyntaxHighlighter language="html" style={isDark ? materialDark : materialLight}>
      {snippet}
    </SyntaxHighlighter>
  );
};

export type Widget = 'chat_widget' | 'review_widget';

interface WidgetConfigurationTableProps<Config, Resource> {
  createFields: Field<Config>[];
  updateFields?: Field<Config>[];
  handleSubmit: (values: any, data?: any) => Resource;
  headCells: readonly [...HeadCell[]];
  initialValues?: any;
  snippet?: (values: Config) => string;
  uidSnippet?: (uid: string) => string;
  createValidationSchema: Yup.AnySchema;
  updateValidationSchema?: Yup.AnySchema;
  remove?: (keyof Config)[];
  widget: Widget;
}

export default function WidgetConfigurationTable<
  Config extends { dealer_name: string },
  Resource extends { uid: string; config: Config }
>({
  createFields,
  updateFields,
  handleSubmit,
  headCells,
  initialValues,
  snippet,
  uidSnippet,
  createValidationSchema,
  updateValidationSchema,
  remove,
  widget,
}: WidgetConfigurationTableProps<Config, Resource>) {
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openImport, setOpenImport] = React.useState(false);

  const { updateTitle } = useForm();

  const { updateDialog } = useDialog();

  const queryClient = useQueryClient();

  const { CSVDownloader, Type } = useCSVDownloader();

  const params = {
    headers: {
      Authorization: `Bearer ${process.env[`REACT_APP_GLOVEBOX_${widget.toUpperCase()}_API_KEY`]}`,
      'Content-Type': 'application/json',
    },
  };

  const {
    isLoading,
    error,
    data: csvData,
  } = useQuery<Resource, AxiosError<Resource>, Configs<Resource>>(`${widget}/configurations`);

  // Update form title
  React.useEffect(() => {
    updateTitle(
      `${widget
        .split('_')
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .join(' ')} Tool`
    );
  }, [updateTitle, widget]);

  const handleOpenCreate = () => setOpenCreate(true);
  const handleCloseCreate = () => setOpenCreate(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  async function onSubmit(values: Config, actions: FormikHelpers<Config>) {
    const submitValue = handleSubmit(values);

    await axios
      .post(`${process.env.REACT_APP_GLOVEBOX_URL}/${widget}/config` as string, submitValue, params)
      .then((r) => r.data)
      .then((data: Resource & { message: string }) => {
        if (data.message) {
          updateDialog({ visible: true, message: data.message, error: true });
        } else {
          updateDialog({
            visible: true,
            message: uidSnippet ? (
              <HighlightedSnippet snippet={uidSnippet(submitValue.uid)} />
            ) : (
              snippet && <HighlightedSnippet snippet={snippet(submitValue.config)} />
            ),
            error: false,
            noColor: true,
            actions: [
              {
                label: 'Copy Snippet',
                onClick: () => {
                  navigator.clipboard.writeText(
                    uidSnippet ? uidSnippet(submitValue.uid) : (snippet && snippet(submitValue.config)) || ''
                  );
                },
              },
            ],
          });
          queryClient.invalidateQueries(`${widget}/configurations`);
          actions.resetForm();
          handleCloseCreate();
        }
      })
      .catch((err: AxiosError) => updateDialog({ visible: true, message: err.message, error: true }));
    actions.setSubmitting(false);
  }

  return (
    <>
      <Modal open={openCreate} onClose={handleCloseCreate}>
        <ModifyWidgetForm
          onCancel={handleCloseCreate}
          validationSchema={createValidationSchema}
          handleSubmit={onSubmit}
          initialValues={initialValues}
          fields={createFields}
          widget={widget}
        />
      </Modal>
      <Modal open={openImport} onClose={handleCloseImport} padding={3}>
        <CSVImport
          createFields={createFields}
          validationSchema={createValidationSchema}
          updateFields={updateFields || createFields}
          initialValues={initialValues}
          snippet={snippet}
          uidSnippet={uidSnippet}
          handleSubmit={handleSubmit}
          widget={widget}
          onCancel={handleCloseImport}
        />
      </Modal>
      <Box sx={{ py: '2rem' }}>
        <FormPaper>
          <Box sx={{ mb: 2 }}>
            <Button sx={{ mr: 1 }} variant="contained" onClick={handleOpenCreate}>
              {'Create Widget'}
            </Button>
            <Button sx={{ mr: 1 }} variant="contained" onClick={handleOpenImport}>
              {'Import CSV'}
            </Button>
            {isLoading || error || !csvData ? (
              <Button variant="contained" disabled>
                {'Export CSV'}
              </Button>
            ) : (
              <CSVDownloader
                data={csvData.configurations.map((resource) => ({ uid: resource.uid, ...resource.config }))}
                type={Type.Link}
                config={{ encoding: 'utf-8' }}
                filename={`${widget}_configurations`}
              >
                <Button variant="contained">{'Export CSV'}</Button>
              </CSVDownloader>
            )}
          </Box>
          <WidgetTable<Config, Resource>
            fields={updateFields || createFields}
            headCells={headCells}
            snippet={snippet}
            uidSnippet={uidSnippet}
            validationSchema={updateValidationSchema || createValidationSchema}
            widget={widget}
            remove={remove}
          />
        </FormPaper>
      </Box>
    </>
  );
}
