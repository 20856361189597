import * as Yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

Yup.addMethod<Yup.StringSchema>(Yup.string, 'isBoolean', function (message) {
  return this.test('isBoolean', message, (val) => val === 'true' || val === 'false');
});

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    isBoolean(message: string): StringSchema<TType, TContext>;
  }
}

export default Yup;
