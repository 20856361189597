import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import CenterBox from '../../components/atoms/CenterBox';
import FormSelect from '../../components/molecules/FormSelect';
import useTheme from '../../hooks/useTheme';
import { FormValues } from './BMAForm';
import useBMAForm from './useBMAForm';

export default function GoogleBusinessInformation() {
  const [showOnlyCheckedLocations, setShowOnlyCheckedLocations] = React.useState(true);

  const { agentData, accountData, locationData, locationError, allLocations } = useBMAForm();

  const { touched, errors, values, handleBlur, handleChange, setFieldValue } = useFormikContext<FormValues>();

  const { theme } = useTheme();

  const fieldProps = { values, touched, handleBlur, errors };

  const handleVerifiedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOnlyCheckedLocations(event.target.checked);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 1000 }}>
      <Typography textAlign="center" mb="2rem" fontWeight="bold" variant="h4">
        {'Google Business Information'}
      </Typography>
      <Box>
        <FormSelect
          fieldKey="account"
          label="GBP Account"
          items={(accountData?.map((acc) => [acc.accountName, acc.name]) as [string, string][]) || ['']}
          handleChange={(e: React.ChangeEvent<any>) => {
            handleChange(e);
            setFieldValue('location', '');
            setFieldValue('agent', '');
          }}
          sx={{ mb: '1em' }}
          {...fieldProps}
        />
      </Box>
      <Box>
        {!agentData
          ? values?.account && (
              <CenterBox mt={2}>
                <CircularProgress />
              </CenterBox>
            )
          : values?.account && (
              <FormSelect
                fieldKey="agent"
                label="Business Message Agent for Verification"
                validateArr={agentData}
                validateKey="verified"
                items={(agentData?.map((agent) => [agent.displayName, agent.name]) as [string, string][]) || ['']}
                disabled={!agentData}
                handleChange={(e: React.ChangeEvent<any>) => {
                  handleChange(e);
                  setFieldValue('location', '');
                }}
                sx={{ mb: '1em' }}
                {...fieldProps}
              />
            )}
      </Box>
      <Box>
        {locationError ? (
          <CenterBox mt={2}>
            <Typography>{locationError.message}</Typography>
          </CenterBox>
        ) : !locationData ? (
          values.agent &&
          values.account && (
            <CenterBox mt={2}>
              <CircularProgress />
            </CenterBox>
          )
        ) : (
          <>
            <FormSelect
              fieldKey="location"
              label="GBP Location"
              items={
                showOnlyCheckedLocations
                  ? (locationData?.map((loc) => [loc.title, loc.name]) as [string, string][]) || ['']
                  : (allLocations?.map((loc) => [loc.title, loc.name]) as [string, string][]) || ['']
              }
              handleChange={handleChange}
              sx={{ mb: '1em' }}
              {...fieldProps}
            />
            <FormControlLabel
              label="Show only locations that pass name check"
              control={
                <Checkbox
                  sx={{ alignSelf: 'flex-start' }}
                  checked={showOnlyCheckedLocations}
                  onChange={handleVerifiedChange}
                />
              }
            />
          </>
        )}
      </Box>
      <FormHelperText sx={{ color: theme.palette.success.main }}>{'Verified'}</FormHelperText>
      <FormHelperText sx={{ color: theme.palette.error.main, mt: 0 }}>{'Unverified'}</FormHelperText>
      {/* <FormLabel>
        {'Show unverified agents only'}
        <Checkbox checked={showUnverifiedAgents} onChange={(v) => setShowUnverifiedAgents(v.target.checked)} />
      </FormLabel> */}
      {/* <Grid item>
          <Typography color="rgba(255,255,255,0.7)" variant="body1">
            {'All locations for this Agent:'}
          </Typography>
          <List dense>
            <ListItem>{'place 1'}</ListItem>
            <ListItem>{'place 2'}</ListItem>
          </List>
          <Button variant="outlined" fullWidth>
            {'Verify Locations'}
          </Button>
        </Grid> */}
    </Box>
  );
}
