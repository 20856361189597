import * as React from 'react';
import { useQuery } from 'react-query';
import { Box, Typography, Grid, Button, CircularProgress } from '@mui/material';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { Formik } from 'formik';
import { motion } from 'framer-motion';
import Yup from 'yup';
import CenterBox from '../atoms/CenterBox';
import ColorPicker from '../molecules/ColorPicker';
import FormField from '../molecules/FormField';
import FormSelect, { Items } from '../molecules/FormSelect';

export interface Field<Config> {
  color?: boolean;
  disabled?: boolean;
  fieldKey: Extract<keyof Config, string>;
  items?: Items;
  label: string;
  notRequired?: boolean;
  placeholder?: string;
}

interface ModifyWidgetFormProps<Config> {
  uid?: string;
  onCancel: () => void;
  validationSchema: Yup.AnySchema;
  handleSubmit: (values: any, actions: any, data?: any, uid?: string) => void;
  initialValues?: any;
  fields: Field<Config>[];
  widget: 'chat_widget' | 'review_widget';
}

export default function ModifyWidgetForm<Config, Resource>({
  uid,
  onCancel,
  validationSchema,
  handleSubmit,
  initialValues,
  fields,
  widget,
}: ModifyWidgetFormProps<Config>) {
  let data: Config | undefined = undefined;
  if (uid) {
    const params = {
      headers: {
        Authorization: `Bearer ${process.env[`REACT_APP_GLOVEBOX_${widget.toUpperCase()}_API_KEY`]}`,
        'Content-Type': 'application/json',
      },
    };

    const {
      isLoading,
      error,
      data: queryData,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useQuery<AxiosResponse<Resource>, AxiosError<Resource>, Config>(
      `config/${uid}`,
      () =>
        axios.get(`${process.env.REACT_APP_GLOVEBOX_URL}/${widget}/config/${uid}` as string, params).then((r) => {
          return r.data.config;
        }),
      { refetchIntervalInBackground: false, refetchOnWindowFocus: false, refetchInterval: 0 }
    );

    if (isLoading) {
      return (
        <CenterBox gap={3}>
          <CircularProgress size={50} />
          <Button fullWidth variant="contained" onClick={onCancel}>
            {'Close'}
          </Button>
        </CenterBox>
      );
    }

    if (error) {
      return (
        <CenterBox gap={3}>
          {error?.message}
          <Button fullWidth variant="contained" onClick={onCancel}>
            {'Close'}
          </Button>
        </CenterBox>
      );
    }

    data = queryData;
  }

  return (
    <Formik
      initialValues={uid ? data : initialValues}
      validationSchema={validationSchema}
      onSubmit={
        uid
          ? (values, actions) => {
              handleSubmit(values, actions, data, uid);
            }
          : handleSubmit
      }
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => {
        const fieldProps = { values, touched, handleBlur, handleChange, errors };
        return (
          <>
            <motion.form layout="size" onSubmit={handleSubmit}>
              <Box
                component={motion.div}
                layout="size"
                sx={{ display: 'flex', flexDirection: 'column', maxWidth: 600, margin: 'auto' }}
              >
                <Typography
                  component={motion.div}
                  layout="position"
                  textAlign="center"
                  mb={2}
                  fontWeight="bold"
                  variant="h4"
                >
                  {`${uid ? 'Update' : 'Create'} ${widget
                    .split('_')
                    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
                    .join(' ')} Configuration`}
                </Typography>
                <Grid component={motion.div} layout container rowSpacing={2} columnSpacing={1}>
                  {fields.map((field) => {
                    const { color, disabled, fieldKey, label, items, notRequired } = field;
                    if (color) {
                      return (
                        <>
                          <Grid component={motion.div} layout item xs={2} sm={1}>
                            <ColorPicker
                              color={values[fieldKey]}
                              onChange={(e) => {
                                setFieldValue(fieldKey, e);
                                handleChange(e);
                              }}
                            />
                          </Grid>
                          <Grid component={motion.div} layout item xs={10} sm={11}>
                            <FormField<Config>
                              fieldKey={fieldKey}
                              disabled={disabled ? Boolean(uid) : false}
                              label={label}
                              initialValue={data?.[fieldKey]}
                              notRequired={notRequired}
                              {...fieldProps}
                            />
                          </Grid>
                        </>
                      );
                    }
                    if (items) {
                      return (
                        <Grid component={motion.div} layout item xs={12}>
                          <FormSelect<Config>
                            items={items}
                            fieldKey={fieldKey}
                            label={label}
                            initialValues={data}
                            notRequired={notRequired}
                            {...fieldProps}
                          />
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid component={motion.div} layout item xs={12}>
                          <FormField<Config>
                            disabled={disabled ? Boolean(uid) : false}
                            fieldKey={fieldKey}
                            label={label}
                            placeholder={field.placeholder}
                            initialValue={data?.[fieldKey]}
                            notRequired={notRequired}
                            {...fieldProps}
                          />
                        </Grid>
                      );
                    }
                  })}
                  <Grid component={motion.div} layout container spacing={1} mt={2}>
                    <Grid component={motion.div} layout item xs={6}>
                      <Button type="submit" fullWidth variant="contained" disabled={isSubmitting}>
                        {'Submit'}
                      </Button>
                    </Grid>
                    <Grid component={motion.div} layout item xs={6}>
                      <Button fullWidth variant="contained" disabled={isSubmitting} onClick={onCancel}>
                        {'Cancel'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </motion.form>
          </>
        );
      }}
    </Formik>
  );
}
