/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Backdrop, Button, CircularProgress, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { ReactComponent as Google } from '../../assets/google.svg';
import CenterBox from '../../components/atoms/CenterBox';
import FormPaper from '../../components/atoms/FormPaper';
import Dialog from '../../components/organisms/Dialog';
import { defaultDialog } from '../../contexts/Dialog';
import useDialog from '../../hooks/useDialog';
import useForm from '../../hooks/useForm';
import useTheme from '../../hooks/useTheme';
import { FormValues, pages } from './BMAForm';
import GoogleBusinessInformation from './Page1';
import BrandContactInformation from './Page2';
import useBMAForm from './useBMAForm';

// Returns the correct page of form.
function renderPageContent(page: number) {
  switch (page) {
    case 0:
      return <GoogleBusinessInformation />;
    case 1:
      return <BrandContactInformation />;
    default:
      return (
        <CenterBox>
          <Typography>{'Not Found'}</Typography>
        </CenterBox>
      );
  }
}

export default function BulkVerificationToolForm() {
  const { updateTitle } = useForm();

  const { theme } = useTheme();
  const { values, handleSubmit, isSubmitting, handleReset, setFieldValue } = useFormikContext<FormValues>();

  const {
    activePage,
    content,
    isLoading,
    isLoadingGoogle,
    isSignedIn,
    updateLocationData,
    updateActivePage,
    handleBack,
    handleAuthClick,
    handleSignoutClick,
    apis,
    updateAgentData,
    updateAllLocations,
    updateLocationError,
  } = useBMAForm();

  const { dialog, updateDialog } = useDialog();

  // Update form title
  React.useEffect(() => {
    updateTitle('Verify Agent Tool');
  }, [updateTitle]);

  // Autofill email & name
  React.useEffect(() => {
    if (isSignedIn) {
      const email = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail();
      const name = 'Friendemic';

      setFieldValue('partner', { name, email });
    }
  }, [isSignedIn, setFieldValue]);

  // Update locations every time a new agent is selected
  React.useEffect(() => {
    async function loadAllLocationData(accountName: string) {
      if (!accountName) {
        return null;
      }

      //load all locations for this account
      return apis?.biAPI.Accounts_Locations_List(accountName);
    }

    async function getLocationData() {
      const locations = await loadAllLocationData(values.account).catch((error) => updateLocationError(error));
      updateAllLocations(locations || null);
      const filteredLocations =
        locations &&
        locations?.filter((location) => {
          if (apis?.biAPI.Agent_Selections_Are_Valid(apis, values.agent, location.name)) {
            apis.biAPI.Location_Name_To_Title[location.name] = location.title;
            return true;
          }
          return false;
        });
      updateLocationData(filteredLocations || null);
    }
    if (isSignedIn && apis && values.agent) {
      updateLocationData(null);
      updateAllLocations(null);
      getLocationData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apis, isSignedIn, values.account, values.agent]);

  React.useEffect(() => {
    async function loadAllAgentData() {
      const agents = await apis?.bcAPI.Account_Agents_List();
      updateAgentData(agents || null);
    }

    if (isSignedIn && apis) {
      loadAllAgentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apis, isSignedIn]);

  const resetForm = () => {
    updateDialog(defaultDialog);
    handleReset();
    updateActivePage(0);
  };

  if (isLoading) {
    return (
      <CenterBox>
        <CircularProgress size={100} />
      </CenterBox>
    );
  }

  if (!isSignedIn) {
    return (
      <CenterBox>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 4,
            padding: 5,
          }}
        >
          <Typography variant="h5">{'Sign in with a Friendemic Google account'}</Typography>
          <LoadingButton
            sx={{ textTransform: 'none', width: 225, py: 1.5 }}
            variant="contained"
            id="authorize-button"
            onClick={handleAuthClick}
            startIcon={<Google />}
            disabled={isLoadingGoogle}
            loading={isLoadingGoogle}
          >
            {'Authorize with Google'}
          </LoadingButton>
        </Paper>
      </CenterBox>
    );
  }

  return (
    <Box sx={{ flex: 1, py: '2rem' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: '2rem',
          mx: 'auto',
          flexDirection: 'column',
        }}
      >
        <Typography mb="1rem" variant="h5" minHeight={'2rem'}>
          {content}
        </Typography>
        <Button sx={{ textTransform: 'none' }} variant="outlined" id="signout-button" onClick={handleSignoutClick}>
          {'Sign Out'}
        </Button>
      </Box>
      <Dialog
        onClose={() => {
          if (!dialog.error) {
            resetForm();
          }
        }}
        actions={[{ label: 'Reset Form', onClick: resetForm }]}
      />
      <FormPaper>
        <Box>
          <Stepper sx={{ mt: 1, mb: 4 }} activeStep={activePage}>
            {pages.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <form onSubmit={handleSubmit}>
          {renderPageContent(activePage)}
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={handleBack}
              sx={{ width: 70 }}
              variant="contained"
              disabled={activePage == 0 || isSubmitting}
            >
              {'Back'}
            </Button>
            <Button type="submit" sx={{ width: 70 }} variant="contained" disabled={isSubmitting}>
              {activePage == pages.length - 1 ? 'Submit' : 'Next'}
            </Button>
          </Box>
          {isSubmitting && (
            <Backdrop sx={{ position: 'absolute', zIndex: theme.zIndex.drawer - 1 }} open={isSubmitting}>
              <CircularProgress size={100} />
            </Backdrop>
          )}
        </form>
      </FormPaper>
    </Box>
  );
}
