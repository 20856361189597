import * as React from 'react';

interface FormContext {
  title: string;
  updateTitle(title: string): void;
  drawerOpen: boolean;
  handleDrawer(open: boolean): void;
}

const FormContext = React.createContext<FormContext>({
  title: '',
  updateTitle: () => null,
  drawerOpen: true,
  handleDrawer: () => null,
});

export default FormContext;

export function FormProvider({ children }: { children: React.ReactNode }) {
  const [title, setTitle] = React.useState('');
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const updateTitle = (title: string) => setTitle(title);
  const handleDrawer = (open: boolean) => setDrawerOpen(open);

  const value = React.useMemo(
    () => ({
      title,
      updateTitle,
      drawerOpen,
      handleDrawer,
    }),
    [drawerOpen, title]
  );

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}
