import * as React from 'react';
import { Grid, Typography, Box, Alert, Divider, FormControl, FormLabel, Input, FormHelperText } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormValues } from './BMAForm';

export default function BrandContactInformation() {
  const { touched, errors, values, handleBlur, handleChange } = useFormikContext<FormValues>();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 1000 }}>
      <Typography mb="2rem" fontWeight="bold" variant="h4">
        {'Brand Contact Information'}
      </Typography>
      <Typography mb={'0.5rem'}>
        {
          'This information is required to verify the Business Message agent, and should be a real email address that is checked regularly. '
        }
      </Typography>
      <Typography>
        {
          "If all goes right, nothing should be required on the customer's end, but it is possible something may not be automatically verified and they will be sent a message."
        }
      </Typography>
      <Alert sx={{ my: '1rem' }} severity="info">
        {
          'Brand Employee email should be a domain name the customer company owns, not a GMail, Yahoo, AOL, Outlook, etc. type email address.'
        }
      </Alert>
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <Typography mb="0.2rem" variant="h5">
            {'Brand Contact'}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl>
            <FormLabel htmlFor="brand.name">{'Name of Brand Employee: '}</FormLabel>
            <Input
              name="brand.name"
              id="brand.name"
              value={values.brand.name}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
              type="text"
              required
            />
            <FormHelperText sx={{ ml: 0 }} error={Boolean(touched?.brand?.name && errors.brand?.name)}>
              {touched?.brand?.name && errors.brand?.name}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl>
            <FormLabel htmlFor="brand.email">{'Email of Brand Employee: '}</FormLabel>
            <Input
              name="brand.email"
              id="brand.email"
              value={values.brand.email}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
              type="text"
              required
            />
            <FormHelperText sx={{ ml: 0 }} error={Boolean(touched?.brand?.email && errors.brand?.email)}>
              {touched?.brand?.email && errors.brand?.email}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl>
            <FormLabel htmlFor="brand.url">{'Brand Website URL: '}</FormLabel>
            <Input
              name="brand.url"
              id="brand.url"
              value={values.brand.url}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
              type="text"
              required
            />
            <FormHelperText sx={{ ml: 0 }} error={Boolean(touched?.brand?.url && errors.brand?.url)}>
              {touched?.brand?.url && errors.brand?.url}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography mb="0.2rem" variant="h5">
            {'Messaging partner (you)'}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl>
            <FormLabel htmlFor="partner.name">{'Your Name: '}</FormLabel>
            <Input
              name="partner.name"
              id="partner.name"
              value={values.partner.name}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
              type="text"
              required
            />
            <FormHelperText sx={{ ml: 0 }} error={Boolean(touched?.partner?.name && errors.partner?.name)}>
              {touched?.partner?.name && errors.partner?.name}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl>
            <FormLabel htmlFor="partner.email">{'Your Email: '}</FormLabel>
            <Input
              name="partner.email"
              id="partner.email"
              value={values.partner.email}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
              type="text"
              required
            />
            <FormHelperText sx={{ ml: 0 }} error={Boolean(touched?.partner?.email && errors.partner?.email)}>
              {touched?.partner?.email && errors.partner?.email}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
