/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { CircularProgress } from '@mui/material';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { BusinessAccountManagementAPI } from '../../api/businessaccountmangement/businessaccountmanagement';
import { GBPAccount } from '../../api/businessaccountmangement/types';
import { BusinessCommunicationsAPI } from '../../api/businesscommunications/businesscommunications';
import { BMAgent, VerificationState } from '../../api/businesscommunications/types';
import { BusinessInformationAPI } from '../../api/businessinformation/businessinformation';
import { GBPLocation } from '../../api/businessinformation/types';
import CenterBox from '../../components/atoms/CenterBox';
import useDialog from '../../hooks/useDialog';
import { BMAVerifySchema } from '../../schemas/forms';

export interface FormValues {
  account: string;
  location: string;
  agent: string;
  brand: { name: string; email: string; url: string };
  partner: { name: string; email: string };
}

export const initialValues: FormValues = {
  account: '',
  location: '',
  agent: '',
  brand: { name: '', email: '', url: '' },
  partner: { name: '', email: '' },
};

const biURL = 'https://mybusinessbusinessinformation.googleapis.com/v1';
const bamURL = 'https://mybusinessaccountmanagement.googleapis.com/v1';
const bcURL = 'https://businesscommunications.googleapis.com/v1';

// a class for easily updating the oauth params for multiple api clients
export class APIBundle {
  biAPI = new BusinessInformationAPI(biURL, '');
  bamAPI = new BusinessAccountManagementAPI(bamURL, '');
  bcAPI = new BusinessCommunicationsAPI(bcURL, '');

  constructor(oauthKey: string) {
    this.biAPI = new BusinessInformationAPI(biURL, oauthKey);
    this.bamAPI = new BusinessAccountManagementAPI(bamURL, oauthKey);
    this.bcAPI = new BusinessCommunicationsAPI(bcURL, oauthKey);
  }
}

const useGoogle = (onLoad: () => void) => {
  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://apis.google.com/js/api.js';
    script.async = true;
    script.defer = true;
    script.onload = onLoad;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [onLoad]);
};

export const pages = ['Google Business Information', 'Brand Contact Information'];

interface BMAFormContext {
  activePage: number;
  isLoading: boolean;
  isSignedIn: boolean;
  isLoadingGoogle: boolean;
  currValues: FormValues;
  content: string;
  agentData: BMAgent[] | null;
  accountData: GBPAccount[] | null;
  locationData: GBPLocation[] | null;
  locationError: Error | null;
  allLocations: GBPLocation[] | null;
  agentNamingCheck: boolean;
  handleAuthClick(e: any): void;
  handleSignoutClick(e: any): void;
  handleBack(): void;
  updateLocationData(data: GBPLocation[] | null): void;
  updateLocationError(error: Error | null): void;
  updateAllLocations(data: GBPLocation[] | null): void;
  updateAgentData(data: BMAgent[] | null): void;
  updateAgentNamingCheck(check: boolean): void;
  updateActivePage(page: number): void;
  updateCurrValues(values: FormValues): void;
  apis: APIBundle | null;
}

const BMAFormContext = React.createContext<BMAFormContext>({
  activePage: 0,
  isLoading: true,
  isSignedIn: false,
  isLoadingGoogle: false,
  currValues: initialValues,
  content: '',
  agentData: null,
  accountData: null,
  locationData: null,
  locationError: null,
  allLocations: null,
  agentNamingCheck: false,
  handleAuthClick: () => null,
  handleSignoutClick: () => null,
  handleBack: () => null,
  updateLocationData: () => null,
  updateLocationError: () => null,
  updateAllLocations: () => null,
  updateAgentData: () => null,
  updateAgentNamingCheck: () => null,
  updateActivePage: () => null,
  updateCurrValues: () => null,
  apis: null,
});

export default BMAFormContext;

/**
 * Wrapper for BMA verify form. Required to allow Formik to pass hook data down to the form.
 *
 * Some higher level form logic lies here as well.
 **/
export function BMAFormProvider({ children }: { children: React.ReactNode }) {
  const [activePage, setActivePage] = React.useState(0);
  const [currValues, setCurrValues] = React.useState(initialValues);
  const [content, setContent] = React.useState<string>('');
  const [agentData, setAgentData] = React.useState<BMAgent[] | null>(null);
  const [accountData, setAccountData] = React.useState<GBPAccount[] | null>(null);
  const [allLocations, setAllLocations] = React.useState<GBPLocation[] | null>(null);
  const [locationData, setLocationData] = React.useState<GBPLocation[] | null>(null);
  const [agentNamingCheck, setAgentNamingCheck] = React.useState(false);
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [isLoadingGoogle, setLoadingGoogle] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [apis, setApis] = React.useState<APIBundle | null>(null);
  const [locationError, setLocationError] = React.useState<Error | null>(null);

  const currentValidationSchema = BMAVerifySchema[activePage];
  const isLastPage = activePage === pages.length - 1;

  const updateLocationError = (error: Error | null) => {
    setLocationError(error);
  };
  const updateLocationData = (data: GBPLocation[] | null) => {
    setLocationData(data);
  };
  const updateAllLocations = (data: GBPLocation[] | null) => {
    setAllLocations(data);
  };
  const updateActivePage = (page: number) => {
    setActivePage(page);
  };
  const updateAgentNamingCheck = (check: boolean) => {
    setAgentNamingCheck(check);
  };
  const updateCurrValues = (values: FormValues) => {
    setCurrValues(values);
  };

  const updateAgentData = (data: BMAgent[] | null) => {
    setAgentData(data);
  };

  const { updateDialog, dialog } = useDialog();

  React.useEffect(() => {
    // fetch all GBP Accounts for the logged in user
    async function loadAllAccountData() {
      return apis?.bamAPI.Accounts_list() || null;
    }

    if (isSignedIn && apis) {
      loadAllAccountData().then((accounts) => {
        setAccountData(accounts);
      });
    }
  }, [apis, apis?.bamAPI, isSignedIn]);

  function getContent(resp: gapi.client.Response<gapi.client.people.Person>) {
    const name = resp?.result?.names?.[0]?.givenName || '';
    setContent(`Hello, ${name}!`);
  }

  function handleClientLoad() {
    // Load the API client and auth2 library
    if (!apis) {
      gapi.load('client:auth2', initClient);
    }
  }

  useGoogle(handleClientLoad);

  function initClient() {
    gapi.client
      .init({
        apiKey: 'AIzaSyBZuo-T-J6cIrKEyS_P6cmW1tOj3rpttGo',
        discoveryDocs: [
          'https://people.googleapis.com/$discovery/rest',
          // 'https://businesscommunications.googleapis.com/$discovery/rest?version=v1',
          // 'https://mybusinessbusinessinformation.googleapis.com/$discovery/rest?version=v1',
          // 'https://mybusinessaccountmanagement.googleapis.com/$discovery/rest?version=v1',
        ],
        clientId: '370975932819-25hkvnd4m2pum6qrtkop3l6lf32c51j3.apps.googleusercontent.com',
        scope: [
          'profile',
          'https://www.googleapis.com/auth/business.manage',
          'https://www.googleapis.com/auth/businesscommunications',
        ].join(' '),
      })
      .then(function () {
        // Listen for sign-in state changes.
        gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

        // Handle the initial sign-in state.
        updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function updateSigninStatus(signinStatus: boolean) {
    if (signinStatus) {
      gapi.client.people.people
        .get({
          resourceName: 'people/me',
          'requestMask.includeField': 'person.names',
        })
        .then((resp) => getContent(resp));

      const token = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
      if (!apis) {
        setApis(new APIBundle(token));
      }
      makePeopleAPICall();
    }
    setIsSignedIn(signinStatus);
    setLoading(false);
  }

  // Google sign in
  const handleAuthClick = () => {
    setLoadingGoogle(true);
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .catch((error: any) => console.error(error))
      .then(() => setLoadingGoogle(false));
  };

  // Google sign out
  const handleSignoutClick = () => {
    gapi.auth2
      .getAuthInstance()
      .signOut()
      .then(() => setContent(''));
  };

  // Load the People API and make an API call.
  function makePeopleAPICall() {
    gapi.client.people.people
      .get({
        resourceName: 'people/me',
        'requestMask.includeField': 'person.names',
      })
      .then(function (resp) {
        getContent(resp);
        setLoadingGoogle(false);
      });
  }

  // Check if the agent matches the location data following the guidelines below
  function errorCheck({ agent, location }: FormValues) {
    const errors: FormikErrors<FormValues> = {};

    // Create an agent with a displayName that matches the name of at least one of the Google My Business listings
    // owned by the OAuth credentials you obtained in the previous step. Look up locations with the
    // Google My Business API to find the names of businesses.
    //
    //     For name matching,
    //     Character casing is insensitive.
    //     Each word within the agent name needs to match to a word within the
    //     GMB location. For example, an agent name "GMB Bakery" matches "Gmb Bakery Mountain View", "Gmb Bakery",
    //     and "gmb bakery sunnyvale", but it doesn't match "Bakery San Francisco".

    // The verification request requires brand contact information, but the verification process won't send an
    // email to the brand if the agent name matches the Business Profile name and if you obtained the OAuth token
    // with the correct scopes.
    if (agent && location) {
      if (!apis?.biAPI.Agent_Selections_Are_Valid(apis, agent, location)) {
        errors.location = 'Agent Naming Check Failed!';
      }
    }

    return errors;
  }

  async function submitForm(values: FormValues, actions: FormikHelpers<FormValues>) {
    let agentVerification = await apis?.bcAPI.Get_Agent_Verification_State(values.agent);
    if (agentVerification && agentVerification !== VerificationState.VERIFIED) {
      const result = await apis?.bcAPI.Verify_Agent(values);
      if (result) {
        if (result && result === VerificationState.VERIFIED) {
          agentVerification = result;
          updateDialog({
            visible: true,
            message: (
              <CenterBox>
                {"Successfully verified Agent. Verifying this agent's Locations..."}
                <CircularProgress />
              </CenterBox>
            ),
            error: false,
            actions: 'none',
          });
        } else {
          updateDialog({
            visible: true,
            message: `Agent auto verification failed. The verification state for agent ${
              apis?.bcAPI.Agent_Name_To_Display_Name[values.agent]
            } is: ${result}. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
            error: true,
          });
        }
      } else {
        updateDialog({ visible: true, message: 'Form did not submit successfully.', error: true });
        return;
      }
    }

    const agentLocations = await apis?.bcAPI.Get_Brand_Locations(values.agent);

    if (agentVerification === VerificationState.VERIFIED) {
      agentLocations?.forEach(async ({ name }) => {
        const locationVerification = await apis?.bcAPI.Verify_Location(name);

        if (locationVerification) {
          if (locationVerification === VerificationState.VERIFIED) {
            updateDialog({
              visible: true,
              message: 'Successfully verified location.',
              error: false,
              actions: [
                {
                  label: 'Reset Form',
                  onClick: () => {
                    actions.resetForm();
                    setActivePage(0);
                    updateDialog({
                      ...dialog,
                      visible: false,
                    });
                  },
                },
              ],
            });
          } else {
            updateDialog({
              visible: true,
              message: `Auto verification of location failed. Verification state is: ${locationVerification} Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
              error: true,
            });
          }
        }
      });
    }

    actions.setSubmitting(false);
  }

  function handleSubmit(values: FormValues, actions: FormikHelpers<FormValues>) {
    if (isLastPage) {
      submitForm(values, actions);
    } else {
      setActivePage(activePage + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  // values to pass down to form.
  const value = React.useMemo(() => {
    const handleBack = () => setActivePage(activePage - 1);
    return {
      activePage,
      isLoading,
      isSignedIn,
      isLoadingGoogle,
      currValues,
      content,
      agentData,
      accountData,
      locationData,
      locationError,
      allLocations,
      agentNamingCheck,
      handleAuthClick,
      handleSignoutClick,
      handleBack,
      updateLocationData,
      updateLocationError,
      updateAllLocations,
      updateAgentData,
      updateAgentNamingCheck,
      updateActivePage,
      updateCurrValues,
      apis,
    };
  }, [
    activePage,
    isLoading,
    isSignedIn,
    isLoadingGoogle,
    currValues,
    content,
    agentData,
    accountData,
    locationData,
    locationError,
    allLocations,
    agentNamingCheck,
    apis,
  ]);

  return (
    <Formik
      initialValues={currValues}
      validate={errorCheck}
      validationSchema={currentValidationSchema}
      onSubmit={handleSubmit}
    >
      <BMAFormContext.Provider value={value}>{children}</BMAFormContext.Provider>
    </Formik>
  );
}
