import { APIBundle } from '../../views/BusinessMessageAgent/BMAForm';
import { BasicOAuthAPIClient } from '../basicOAuthAPIClient';
import { BusinessInformationLocationsList } from './types';

export class BusinessInformationAPI extends BasicOAuthAPIClient {
  Location_Name_To_Title: { [key: string]: string } = {};

  Get_Location_Title(locationName: string) {
    return this.Location_Name_To_Title[locationName];
  }

  Agent_Selections_Are_Valid(apis: APIBundle, agent: string, location: string) {
    const lowercaseAgentName = apis?.bcAPI.Get_Agent_Display_Name(agent).toLowerCase();
    const lowercaseLocationTitle = this.Get_Location_Title(location).toLowerCase();

    const agentWords = lowercaseAgentName?.split(' ');
    const locationWords = lowercaseLocationTitle?.split(' ');

    const missingWords: string[] = [];

    agentWords?.forEach((agentWord: string) => {
      if (!locationWords?.includes(agentWord)) {
        missingWords.push(agentWord);
      }
    });
    return missingWords.length === 0;
  }

  async Accounts_Locations_List(accountName: string) {
    const response = await this.get(`/${accountName}/locations?readMask=name,title&pageSize=100`);
    const allLocations: BusinessInformationLocationsList = await response.json();
    while (allLocations.nextPageToken !== undefined) {
      const nextPageResponse = await this.get(
        `/${accountName}/locations?readMask=name,title&pageSize=100&pageToken=${allLocations.nextPageToken}`
      );
      const nextPageLocations: BusinessInformationLocationsList = await nextPageResponse.json();
      allLocations.locations.push(...nextPageLocations.locations);
      allLocations.nextPageToken = nextPageLocations.nextPageToken;
    }
    if (!allLocations.locations || allLocations.locations.length === 0) {
      throw new Error('No locations found!');
    }
    allLocations.locations.forEach((location) => {
      this.Location_Name_To_Title[location.name] = location.title;
    });
    return allLocations.locations;
  }
}
