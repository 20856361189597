import * as React from 'react';
import MuiPaper, { PaperProps as MuiPaperProps } from '@mui/material/Paper';
import styled from '@mui/system/styled';

const StyledPaper = styled(MuiPaper)(({ theme, noPadding, noMargin, sx }) => ({
  padding: !noPadding && theme.spacing(5),
  margin: noMargin ? 0 : 'auto',
  maxWidth: 'fit-content',
  position: 'relative',
  bgcolor: theme.palette.background.paper,
  overflowX: 'auto',
  ...sx,
}));

export interface FormPaperProps extends MuiPaperProps {
  children: React.ReactNode;
  noPadding?: boolean;
  noMargin?: boolean;
}

export default function FormPaper({ children, ...props }: FormPaperProps) {
  return <StyledPaper {...props}>{children}</StyledPaper>;
}
