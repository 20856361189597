import darkScrollbar from '@mui/material/darkScrollbar';
import { createTheme } from '@mui/material/styles';

export const drawerWidth = '16.5rem';

export const darkTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: darkScrollbar(),
      },
    },
    // MuiTypography: {
    //   styleOverrides: { paragraph: { letterSpacing: 5 } },
    // },
  },
  palette: {
    mode: 'dark',
    common: {
      black: '#FFFFFF',
      white: '#000000',
    },
    background: {
      default: '#2a2a2a',
      paper: '#262626',
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#fafafa',
      paper: '#fcfcfc',
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
});

export default darkTheme;
