import { BasicOAuthAPIClient } from '../basicOAuthAPIClient';
import { BusinessAccountManagementAccountsList } from './types';

export class BusinessAccountManagementAPI extends BasicOAuthAPIClient {
  async Accounts_list() {
    const response = await this.get('/accounts');
    const allAccounts: BusinessAccountManagementAccountsList = await response.json();
    return allAccounts.accounts;
  }
}
