import * as React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { NavRoutes } from '../../Routes';

interface PageRoutesProps {
  routes: NavRoutes;
}

export default function PageRoutes({ routes }: PageRoutesProps) {
  return (
    <Routes>
      <Route
        path="/"
        element={
          Array.isArray(routes.top[0]) ? (
            <Navigate replace to={routes?.top?.[0]?.[1]?.path ? routes?.top?.[0]?.[1]?.path : ''} />
          ) : (
            <Navigate replace to={routes?.top?.[0]?.path ? routes.top[0].path : ''} />
          )
        }
      />
      {routes.top.map((route) => {
        if (Array.isArray(route)) {
          const [title, ...routes] = route;
          return (
            <>
              {routes.map((nestedRoute) => (
                <Route path={nestedRoute.path} element={nestedRoute.element} key={`${title}/${nestedRoute.path}`} />
              ))}
            </>
          );
        }
        return <Route path={route.path} element={route.element} key={route.path} />;
      })}
      {routes.bottom.map((route) => (
        <Route path={route.path} element={route.element} key={route.path} />
      ))}
    </Routes>
  );
}
