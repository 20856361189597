import * as React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

interface CenterBoxProps extends BoxProps {
  children: React.ReactNode;
  fullScreen?: boolean;
  row?: boolean;
}

export default function CenterBox({ children, fullScreen, row, ...props }: CenterBoxProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: row ? 'row' : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: fullScreen ? '100vh' : '100%',
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
