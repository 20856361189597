import * as React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import MuiListItem, { ListItemButtonProps as MuiListItemProps } from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import styled from '@mui/system/styled';
import FontAwesomeScalableIcon from '../atoms/FontAwesomeScalableIcon';

interface NavItemProps extends MuiListItemProps {
  isActive: boolean;
  title: string;
  icon?: IconProp | null;
  children?: React.ReactNode;
}

const StyledListItemText = styled(ListItemText)({});

export default function NavItem({ isActive, title, icon, children, ...props }: NavItemProps) {
  return (
    <MuiListItem selected={isActive} {...props}>
      <ListItemIcon sx={{ minWidth: 40 }}>
        <FontAwesomeScalableIcon icon={icon ? icon : ['fas', 'clipboard']} />
      </ListItemIcon>
      <StyledListItemText primary={title} primaryTypographyProps={{ sx: { fontWeight: 600 } }} />
      {children}
    </MuiListItem>
  );
}
