import * as React from 'react';
import MuiTooltip, { TooltipProps as MuiTooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from '@mui/system/styled';

const StyledTooltip = styled(({ className, ...props }: MuiTooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({ [`& .${tooltipClasses.tooltip}`]: { color: theme.palette.common.black, fontSize: 12 } }));

export default function Tooltip({ children, title = '', ...props }: MuiTooltipProps) {
  return (
    <StyledTooltip placement="top" arrow title={title} {...props}>
      {children}
    </StyledTooltip>
  );
}
