import * as React from 'react';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import useTheme from '../../hooks/useTheme';
import FormPaper from './FormPaper';

interface ModalProps extends MuiDialogProps {
  padding?: number;
}

export default function Modal({ children, padding, ...props }: ModalProps) {
  const { theme } = useTheme();
  return (
    <MuiDialog maxWidth="lg" {...props}>
      <FormPaper
        sx={{ overflowX: 'hidden', display: 'flex', maxWidth: '100%', padding: padding ? padding : theme.spacing(5) }}
      >
        {children}
      </FormPaper>
    </MuiDialog>
  );
}
