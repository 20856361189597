import * as React from 'react';
import { Box } from '@mui/material';
import AppWrapper from './AppWrapper';
import Drawer from './components/molecules/Drawer';
import NavList from './components/molecules/NavList';
import Header from './components/organisms/Header';
import PageRoutes from './components/organisms/PageRoutes';
import useForm from './hooks/useForm';
import NavRoutes from './Routes';
import { drawerWidth } from './theme';
import { fontAwesomeConfig } from './utils/fontawesomeconfig';

function App() {
  fontAwesomeConfig.init();

  const { title } = useForm();

  return (
    <AppWrapper>
      <Box
        sx={{
          display: 'flex',
          height: 'calc(100vh - 20px)',
        }}
      >
        <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
          <Drawer>
            <NavList routes={NavRoutes} />
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flex: 1,
            p: 3,
            minHeight: 'fit-content',
            width: 0,
          }}
        >
          <Header title={title} />
          <PageRoutes routes={NavRoutes} />
        </Box>
      </Box>
    </AppWrapper>
  );
}

export default App;
