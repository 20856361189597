import * as React from 'react';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import useForm from '../../hooks/useForm';
import FontAwesomeScalableIcon from '../atoms/FontAwesomeScalableIcon';

interface HeaderProps {
  title: string;
}

export default function Header({ title }: HeaderProps) {
  const { drawerOpen, handleDrawer } = useForm();

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2, pb: 1 }}>
        <IconButton
          edge="start"
          onClick={() => handleDrawer(!drawerOpen)}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          <FontAwesomeScalableIcon icon={['fas', 'bars']} />
        </IconButton>
        <Typography textAlign="center" variant="h4">
          {title}
        </Typography>
      </Box>
      <Divider />
    </>
  );
}
