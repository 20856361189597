import Yup from './yup-extended';

export const BMAVerifySchema = [
  Yup.object().shape({
    account: Yup.string().required('A Google Business Account is required'),
    location: Yup.string().required('A Google Business Location is required.'),
    agent: Yup.string().required('A Google Business Message Agent is required.'),
  }),
  Yup.object().shape({
    brand: Yup.object().shape({
      name: Yup.string().required('Name of Brand Employee is required'),
      email: Yup.string().email().required('Email of Brand Employee is required'),
      url: Yup.string().url().required('URL of Brand Employee is required'),
    }),
    partner: Yup.object().shape({
      name: Yup.string().required('Your Name is required'),
      email: Yup.string().email().required('Your Email is required'),
    }),
  }),
];

const HexColorRegex = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
const getDigits = (val: string | undefined) => val?.replace(/\D/g, '') || '';

export const ChatWidgetSchema = Yup.object().shape({
  app_id: Yup.string().default('DED6FF3E-89E3-40BF-8F1F-32B069008DE8').required('App ID is required'),
  sms_chat_reply_number: Yup.string()
    .notRequired()
    .test('length', 'Phone number must be 10 or 11 digits', (val) => {
      const digits = getDigits(val);
      if (digits.length === 10 || digits.length === 11 || !Boolean(digits)) {
        return true;
      }
      return false;
    })
    .test('letters', 'Phone number must only contain digits', (val) => {
      if (/[\D]/.test(val || '')) {
        return false;
      }
      return true;
    }),
  dealer_name: Yup.string().required('Dealer Name is required'),
  dealer_code: Yup.string().notRequired(),
  header_logo: Yup.string().required('Header Logo is required'),
  theme_bg_color: Yup.string()
    .default('#F7F7F7')
    .required('A Theme Background Color is required')
    .matches(HexColorRegex, 'Theme Background Color must be in Hex format (#000000)'),
  theme_text_color: Yup.string()
    .default('#363636')
    .required('A Theme Text Color is required')
    .matches(HexColorRegex, 'Theme Text Color must be in Hex format (#000000)'),
  DEBUG: Yup.string().isBoolean('Debug must be a boolean string').default('true').required('DEBUG value is required'),
});

export const ReviewWidgetSchema = Yup.object().shape({
  profile_id: Yup.string().required('Profile ID is required'),
  is_french: Yup.string()
    .isBoolean('is_french must be a boolean string')
    .default('false')
    .required('Language is required'),
  excluded_keywords: Yup.string().notRequired(),
  dealer_name: Yup.string().required('Dealer Name is required'),
  theme_bg_color: Yup.string()
    .default('#F7F7F7')
    .required('A Theme Background Color is required')
    .matches(HexColorRegex, 'Theme Background Color must be in Hex format (#000000)'),
  theme_text_color: Yup.string()
    .default('#363636')
    .required('A Theme Text Color is required')
    .matches(HexColorRegex, 'Theme Text Color must be in Hex format (#000000)'),
  DEBUG: Yup.string().isBoolean('DEBUG must be a boolean string').default('true').required('DEBUG value is required'),
});
