import * as React from 'react';
import {
  Button,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { defaultDialog } from '../../contexts/Dialog';
import useDialog from '../../hooks/useDialog';
import useTheme from '../../hooks/useTheme';

interface DialogProps {
  onClose?: () => void;
  actions?: { label: string; onClick: React.MouseEventHandler<HTMLButtonElement> }[];
}

export default function Dialog({ onClose }: DialogProps) {
  const { dialog, updateDialog, dialogOpen } = useDialog();
  const { theme } = useTheme();

  return (
    <MuiDialog
      maxWidth="lg"
      open={dialogOpen}
      onClose={() => {
        updateDialog({ ...dialog, visible: false });
        onClose;
      }}
    >
      <DialogTitle>
        {dialog.title ? dialog.title : dialog.error ? 'Submission Failed' : 'Submission Succeeded'}
      </DialogTitle>
      <DialogContent>
        {typeof dialog.message === 'object' ? (
          dialog.message
        ) : (
          <DialogContentText
            color={
              !dialog.noColor ? (dialog.error ? theme.palette.error.main : theme.palette.success.main) : 'lightgray'
            }
          >
            {dialog.message}
          </DialogContentText>
        )}
        <DialogActions>
          {!dialog.error && Array.isArray(dialog.actions) ? (
            dialog.actions?.map((action) => (
              <Button color={action.color} onClick={action.onClick} key={action.label}>
                {action.label}
              </Button>
            ))
          ) : (
            <></>
          )}
          {dialog.actions !== 'none' && (
            <Button onClick={() => updateDialog(defaultDialog)}>{dialog.no ? 'no' : 'Close'}</Button>
          )}
        </DialogActions>
      </DialogContent>
    </MuiDialog>
  );
}
